import React from 'react'
import { Link } from 'react-router-dom';
import help_center from "../../assets/resourcesDropDown/help_center.png";
import case_studies from "../../assets/resourcesDropDown/case_studies.png";
import blogs from "../../assets/resourcesDropDown/blog.png";
import webinars from "../../assets/resourcesDropDown/webinars.png";

import { FaPersonCircleCheck } from "react-icons/fa6";
import { CgWebsite } from "react-icons/cg";
import { SlEnvolopeLetter } from "react-icons/sl";
import { MdWeb } from "react-icons/md";
export const Explore_Products = () => {
    const explore_product_array = [
        {
            name: "BGV HR Portal",
            image: <CgWebsite />,
            path: "/bgv-hr-portal"
        },
        {
            name: "Offer Experience",
            image: <SlEnvolopeLetter />,
            path: "/offer-experience"
        },
        {
            name: "Self Verification",
            image: <FaPersonCircleCheck />,
            path: "/self-verification"
        },
        {
            name: "GR+ Portal",
            image: <MdWeb />,
            path: "/gr-plus-portal"
        }
    ];
    return (
        <div>  <div className="mt-8 pt-12 pb-12 FontFamily">
            <div className="text-center py-12 ">
                <p className="text-xl font-base font-bold tracking-wide uppercase text-gray-400">
                    Explore Products
                </p>
            </div>
            <div className="flex gap-8 justify-items-center p-4 flex-wrap px-16">
                {explore_product_array?.map((ele) => (
                    <div className="border-2 border-gray-200 hover:border-gray-300 min-h-[10vw] flex-auto">
                        <Link to={ele.path} className="flex justify-center flex-col gap-y-4 items-center relative font-bold py-5 px-16 ">
                            <div className="w-8">
                            {/*     <img src={ele.image} alt="" className="w-8 object-cover" /> */}
                                <p className="text-sky-700 text-4xl text-center">{ele.image}</p>
                            </div>
                            <p>{ele.name}</p>
                        </Link>
                    </div>
                ))}
            </div>
        </div></div>
    )
}
