import React, { useEffect } from "react";
import { Help } from "./Help";

export const Faq = () => {
  const faqArray = [
    {
      question:
        "1.	What is Experience and Employee Background Verification powered by Blockchain?",
      answer:
        "We leverage blockchain technology for the creation of offer letters and employment verification. When a company utilizes our offer portal to generate offer letters, it gains visibility into the number of current offer letters held by a candidate. This preemptive insight enables the company to discern whether the candidate is genuinely committed to joining or simply engaging in offer shopping. Additionally, during the verification of a candidate's past employment, if their previous companies have already validated the employment details, the new company can streamline the verification process, saving valuable time and resources that would otherwise be spent on re-verifying each past employer.",
    },
    {
      question:
        "2.	How does blockchain technology enhance the verification process?",
      answer:
        "Blockchain technology enhances the verification process by providing a secure and transparent decentralized ledger. It ensures the integrity and immutability of data, making it tamper-resistant. Through smart contracts and consensus mechanisms, blockchain establishes trust in the verification process, reducing the risk of fraud or manipulation.",
    },
    {
      question:
        "3.	Why is blockchain verification more secure than traditional methods?",
      answer:
        "Blockchain verification offers heightened security compared to traditional methods due to its features. Immutability ensures data integrity, making it nearly impervious to tampering. Decentralization reduces vulnerabilities associated with centralized control, while cryptographic techniques secure transactions. Transparency and smart contracts further enhance trust by providing real-time visibility and automating verification processes. These combined features make blockchain a robust and secure solution for various applications, surpassing the security offered by many traditional verification methods.",
    },
    {
      question:
        "4.	What types of employee information can be verified using this service?",
      answer:
        "This service facilitates the verification of various employee information, including but not limited to employment history, job titles, dates of employment, and salary details. Additionally, educational qualifications, professional certifications, and any relevant licenses can be verified. The platform ensures a comprehensive and reliable verification process, helping employers confirm the accuracy of crucial details about potential hires, promoting transparency and trust in the verification process.",
    },
    {
      question: "5.	How long does the verification process take?",
      answer:
        "With existing blockchain records, the verification process for degree and past employment details can be completed within minutes. However, in the absence of such records, the timeframe may extend to 2-7 days, contingent upon the responsiveness of the university and company. Other particulars such as PAN, Aadhaar, and driving licenses (with or without a facial component) can be swiftly verified within minutes. Crime checks, the most time-intensive process, will be concluded within a maximum of 2 days.",
    },
    {
      question:
        "6.	Is the information stored on the blockchain accessible by anyone?",
      answer:
        "Yes, those person who have subscribed to the services available on blockchain. They will be able to access the information available on blockchain",
    }
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div class="max-w-screen-xl mx-auto px-5 bg-white min-h-sceen mt-24 FontFamily">
      <div class="flex flex-col items-center">
        <h2 class="font-bold text-5xl mt-5 tracking-tight">FAQs</h2>
      </div>
      <div class="grid divide-y divide-neutral-200 max-w-3xl mx-auto mt-8">
        {faqArray.map((ele) => (
          <div class="py-5" key={ele.question}>
            <details class="group">
              <summary class="flex justify-between items-center font-semibold text-xl cursor-pointer list-none">
                <span>{ele.question}</span>
                <span class="transition group-open:rotate-180">
                  <svg
                    fill="none"
                    height="24"
                    shape-rendering="geometricPrecision"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path d="M6 9l6 6 6-6"></path>
                  </svg>
                </span>
              </summary>
              <p class="text-neutral-600 mt-3 group-open:animate-fadeIn">
                {ele.answer}
              </p>
            </details>
          </div>
        ))}
      </div>
      <div className="w-full">
        <Help />
      </div>
    </div>
  );
};
