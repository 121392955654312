import React from "react";
import { Link } from "react-router-dom";

export const ServicesComponents = ({
  service_name,
  image,
  description,
  path,
}) => {
  return (
    <Link to={path}>
      <div
        className="flex gap-x-2 content-center cursor-pointer "
        key={service_name}
      >
        <img src={image} alt="" className="w-[15px] h-[16px] mt-2"></img>
        <div className="">
          <p className="text-lg  font-bold text-sky-600 transition-[margin] duration-300 hover:mr-1">
            {service_name}
          </p>
          <p className=" text-md font-normal">{description}</p>
        </div>
      </div>
    </Link>
  );
};
