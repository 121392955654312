import React, { useState } from "react";
import identity from "../../assets/servicesImages/identity.png";
import degree from "../../assets/servicesImages/degree.png";
import employement from "../../assets/servicesImages/employement.png";
import financial from "../../assets/servicesImages/financial.png";
import health from "../../assets/servicesImages/health.png";
import crime_check from "../../assets/servicesImages/crime_check.png";
import arrow from "../../assets/servicesImages/arrow.png"
import { Link } from "react-router-dom";

export const ServicesDropdown = ({ setIsServicesHovered }) => {
  const servicesArray = [
    {
      service_name: "Identity Verification",
      image: identity,
    },
    {
      service_name: "Degree Verification",
      image: degree,
    },
    {
      service_name: "Employement Verification",
      image: employement,
    },
    {
      service_name: "Healthcare Screening",
      image: health,
    },
    {
      service_name: "Financial Integrity Check",
      image: financial,
    },
    {
      service_name: "Crime Check",
      image: crime_check,
    },
  ];

  const industriesArray = [
    { title: "Fintech" },
    { title: "HR, Recruitment" },
    { title: "Tech" },
    { title: "BPO" },
    { title: "EOR" },
  ];

  return (
    <div
      className="container mx-auto flex justify-evenly -left-[300px] bg-white absolute p-4 w-[520px] h-[310px] rounded-lg gap-2 pt-12 shadow-md z-10"
      // style={{
      //   width: "1262px",
      //   left: "-795px",
      //   top: "55px",
      //   backgroundColor: "white",
      // }}
      onMouseEnter={() => setIsServicesHovered(true)}
      onMouseLeave={() => setIsServicesHovered(false)}
    >
      <div className="grid grid-cols-1 gap-1 items-center ">
        {servicesArray?.map((ele) => {
          return (
            <div className="cursor-pointer" key={ele.nameOfService}>
              <Link to="/industry-support" >    <div className="popular_checks_container">

                <div className="">
                  <img
                    src={ele.image}
                    alt=""
                    className="w-[21px] h-[15px] object-contain"
                  />
                </div>
                <div className="">
                  <p className="text-xl font-medium text-sky-600">
                    {ele.service_name}
                  </p>
                </div>

                {/* <span>{ele.image}</span> */}
              </div>
              </Link>
            </div>
          );
        })}
      </div>
      <div className="grid grid-cols-1 gap-1 items-center">
        <div>
          <p className="text-xl font-medium text-sky-600 ml-1">Industries Supported</p>
        </div>
        {industriesArray.map((ind) => (
          <div className="cursor-pointer" key={ind.title}>
            <div className="popular_checks_container flex justify-start items-center w-auto">
              <div className="">
                <img
                  src={arrow}
                  alt=""
                  className="w-[21px] h-[15px] object-contain"
                />
              </div>
              <Link to="/industry-support" >
                <div className="">
                  <p className="text-xl font-medium text-sky-600">{ind.title}</p>
                </div>
              </Link>
              {/* <span>{ele.image}</span> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
