import React, { useState } from "react";
// import { MdArrowForwardIos } from "react-icons/md";
import { Resource_card } from "./Resource_card";
import { DropDownCard } from "./DropDownCard";
import blogs from "../../assets/resourcesDropDown/blog.png";
import webinars from "../../assets/resourcesDropDown/webinars.png";
import customer_stories from "../../assets/resourcesDropDown/customer_stories.png";
import help_center from "../../assets/resourcesDropDown/help_center.png";
import case_studies from "../../assets/resourcesDropDown/case_studies.png";
import reports from "../../assets/resourcesDropDown/reports.png";
import { ServicesComponents } from "../Navbar_Product_Dropdown/ServicesComponents";
// import "./Navbar_Product_Dropdown.css"

export const ResourcesDropdown = ({ setIsResourcesHovered }) => {
  const resourcesArray = [
    {
      service_name: "Blogs",
      description:
        "Stay updated with industry trends and company news in our blog",
      image: blogs,
      path: "/blogs",
    },
    {
      service_name: "Videos",
      description:
        "Engage in webinars and events focused on hiring excellence.",
      image: webinars,
      path: "/videos",
    },
    {
      service_name: "Case Studies",
      description: "Discover business success stories with our solutions.",
      image: case_studies,
      path: "/case-studies",
    },

    {
      service_name: "Help Center",
      description: "Connect with dedicated support resources for assistance.",
      image: help_center,
      path: "/help-center",
    },
  ];

  return (
    <div
      className="container FontFamily absolute p-4 bg-white -left-[500px] flex gap-2 h-[377px] rounded-lg pt-12 z-10 w-[880px] "
      // onMouseEnter={() => setIsResourcesHovered(true)}
      // onMouseLeave={() => setIsResourcesHovered(false)}
      // style={{width:"1262px",left:"-670px",top:"55px",backgroundColor:"white"}}
    >
      <div className="flex gap-x-6 mx-auto">
        <div className="grid grid-cols-2 gap-x-4 w-[512px] h-[297px] mt-2">
          {resourcesArray?.map((ele) => {
            return (
              <ServicesComponents
                service_name={ele.service_name}
                image={ele.image}
                description={ele.description}
                path={ele.path}
              />
              // <div className="cursor-pointer" key={ele.nameOfService}>
              //   <div className="popular_checks_container">
              //     <img src={ele.image} alt="" className="h-[16px] w-[13px] object-contain" />
              //     <p className="text-lg font-bold text-sky-600">
              //       {ele.service_name}
              //     </p>
              //     {/* <span>{ele.image}</span> */}
              //   </div>
              //   <p className="text-md font-normal">{ele.description}</p>
              // </div>
            );
          })}
          {/* <div className="grid bg-cyan-50 p-3 col-span-2 justify-items-center content-center cursor-pointer">
          <p className="text-xl font-medium text-sky-600">Contact us</p>
          <p>We love hearing from you</p>
        </div> */}
        </div>
        <div className="w-[272px] h-[297px]">
          <DropDownCard />
          {/* <Resource_card
          image={
            "https://assets-global.website-files.com/63fef929dc32cb28d93d6c87/65296f5cfda628b02680f346_64807aba6d3337483bdb6e09_kai-pilger-cBsGDi2mQ7c-unsplash%20(1)%20(1).jpg"
          }
          title={
            "Mastering Background Verification in the Digital Age"
          }
          author={"An in-depth guide on leveraging technology for reliable background verification in modern hiring."}
          // readingTime={""}
        /> */}
        </div>
      </div>
    </div>
  );
};
