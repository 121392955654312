import React from "react";
import { Route, Routes, Switch } from "react-router";
import { BlogPage } from "../Blog/BlogPage";
import { CaseStudy } from "../Case_Study/Case_Study";
import { IndividualBlogPage } from "../Blog/IndividualBlogPage/IndividualBlogPage";
import { blogArray, clients_case_study_array } from "../../blog";
import { VideoPage } from "../VideoPage/VideoPage";
import { GR_portal } from "../../Products/GR_portal";
import { OffersProduct } from "../../Products/OffersProduct";
import { LandingPage } from "../LandingPage/LandingPage";
import { Faq } from "../Case_Study/Faq";
import { ResourcesPage } from "../Resources_dropdown/ResourcesPage";
import { ContactUs } from "../ContactUs/ContactUs";
import { IndustriesSupport } from "../IndustriesSupport/IndustriesSupport";
import { ProductsPage } from "../../Products/ProductsPage";
import { IndividualCaseStudyPage } from "../Case_Study/IndividualCaseStudyPage";
import { PageNotFound } from "../PageNotFound";
import { BGV_HRportal } from "../../Products/BGV_HRportal";
import { Self_Verification } from "../../Products/Self_Verification";

export const AllRoutes = () => {
  // console.log(blogArray);
  return (
    <Routes>
      <Route path="/blogs" element={<BlogPage blogArray={blogArray} />}></Route>
      <Route
        path="/case-studies"
        element={
          <CaseStudy clients_case_study_array={clients_case_study_array} />
        }
      />
      <Route
        path="/blogs/:title"
        element={<IndividualBlogPage blogArray={blogArray} />}
      ></Route>
      <Route path="/videos" element={<VideoPage />}></Route>
      <Route path="/" element={<LandingPage />}></Route>
      <Route path="/contact-us" element={<ContactUs />}></Route>
      <Route path="/help-center" element={<Faq />}></Route>
      <Route path="/resources" element={<ResourcesPage />}></Route>
      <Route path="/products" element={<ProductsPage />}></Route>
      <Route path="/offer-experience" element={<OffersProduct />}></Route>
      <Route path="/bgv-hr-portal" element={<BGV_HRportal />}></Route>
      <Route path="/self-verification" element={<Self_Verification />}></Route>
      <Route path="/gr-plus-portal" element={<GR_portal />}></Route>
      <Route path="/industry-support" element={<IndustriesSupport />}></Route>
      <Route path="*" element={<PageNotFound />}></Route>
      <Route
        path="/case-studies/:title"
        element={
          <IndividualCaseStudyPage
            clients_case_study_array={clients_case_study_array}
          />
        }
      ></Route>
    </Routes>
  );
};
