export const videoArray = [
  { title: "About Hirestar.io", videoUrl: "https://www.youtube.com/watch?v=07ep7PP6IE8" },
  { title: "Background verification", videoUrl: "https://www.youtube.com/watch?v=6gAVlJZEtjo" },
  { title: "How Absconding Works", videoUrl: "https://www.youtube.com/watch?v=07ep7PP6IE8" },
  { title: "Importance of crime check", videoUrl: "https://www.youtube.com/watch?v=PKUPlSSum_E" },
  { title: "Degree verification", videoUrl: "https://www.youtube.com/watch?v=rhLT6_Rr6qI" },
  { title: "Hirestar moonlighting", videoUrl: "https://www.youtube.com/watch?v=odDZW2YhH44&t=21s" },
  { title: "Offer experience", videoUrl: "https://www.youtube.com/watch?v=Hm1lSM8gu1A" },
  {
    title: "Report a candidate termination at GR +",
    videoUrl: "https://www.youtube.com/watch?v=AyMT_hHlzmU",
  },
  {
    title: "Report an absconder or moonlighter at GR+",
    videoUrl: "https://www.youtube.com/watch?v=5S6RpgUXV4M",
  },
  {
    title: "Report an offer shopper at GR+",
    videoUrl: "https://www.youtube.com/watch?v=dfYQLU_un18",
  },
  {
    title: "Search candidated and profile details",
    videoUrl: "https://www.youtube.com/watch?v=mKUg9GFy3Tg",
  },
];
