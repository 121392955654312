import React, { useRef, useState } from "react";
import { Resource_card } from "../Resources_dropdown/Resource_card";
import { Link } from "react-router-dom";
import $ from "jquery";
import "./Carousel.css";
// import './App.css';

export const Carousel = ({ carouselArray }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const dragStartX = useRef(0);
  const dragEndX = useRef(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % 3);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + 3) % 3);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    dragStartX.current = e.clientX;
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    dragEndX.current = e.clientX;
    const diff = dragEndX.current - dragStartX.current;

    $(".carousel-inner").css(
      "transform",
      `translateX(-${currentSlide * 100}%) translateX(${diff}px)`
    );
  };

  const handleMouseUp = () => {
    if (!isDragging) return;
    setIsDragging(false);

    const diff = dragEndX.current - dragStartX.current;

    if (Math.abs(diff) > 50) {
      diff > 0 ? prevSlide() : nextSlide();
    }

    $(".carousel-inner").css("transform", `translateX(0)`);
  };

  return (
    <div className=" w-full relative mx-auto FontFamily">
      <div
        className="overflow-hidden rounded-md carousel-inner"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <div
          className="flex transition-transform duration-300 transform translate-x-full"
          style={{ transform: `translateX(-${currentSlide * 100}%)` }}
        >
          <div className="w-full flex flex-shrink-0">
            <div className="py-8 pl-4 lg:w-1/2 md:w-1/3 sm:w-1/4">
              <Link to={`/blogs/${carouselArray[0].title.replace(/ /g, "+")}`} className="">
                <Resource_card
                  image={carouselArray[0].image}
                  title={carouselArray[0].title}
                  author={""}
                  readingTime={""}
                />
              </Link>
            </div>
            <div className="py-8 pr-4 lg:w-1/2 md:w-1/3 sm:w-1/4">
              <Link to={`/blogs/${carouselArray[1].title.replace(/ /g, "+")}`} className="">
                <Resource_card
                  image={carouselArray[1].image}
                  title={carouselArray[1].title}
                  author={""}
                  readingTime={""}
                />
              </Link>
            </div>
          </div>
          <div className="w-full flex flex-shrink-0">
            <div className="py-8 pl-4 lg:w-1/2 md:w-1/3 sm:w-1/4">
              <Link to={`/blogs/${carouselArray[2].title.replace(/ /g, "+")}`} className="">
                <Resource_card
                  image={carouselArray[2].image}
                  title={carouselArray[2].title}
                  author={""}
                  readingTime={""}
                />
              </Link>
            </div>
            <div className="py-8 pr-4 lg:w-1/2 md:w-1/3 sm:w-1/4">
              <Link to={`/blogs/${carouselArray[3].title.replace(/ /g, "+")}`} className="">
                <Resource_card
                  image={carouselArray[3].image}
                  title={carouselArray[3].title}
                  author={""}
                  readingTime={""}
                />
              </Link>
            </div>
          </div>
          <div className="w-full flex flex-shrink-0">
            <div className="py-8 pl-4 lg:w-1/2 md:w-1/3 sm:w-1/4">
              <Link to={`/blogs/${carouselArray[4].title.replace(/ /g, "+")}`} className="">
                <Resource_card
                  image={carouselArray[4].image}
                  title={carouselArray[4].title}
                  author={""}
                  readingTime={""}
                />
              </Link>
            </div>
            <div className="py-8 pr-4  lg:w-1/2 md:w-1/3 sm:w-1/4">
              <Link to={`/blogs/${carouselArray[5].title.replace(/ /g, "+")}`} className="">
                <Resource_card
                  image={carouselArray[5].image}
                  title={carouselArray[5].title}
                  author={""}
                  readingTime={""}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="">
      <div className="absolute hiding_display left-1/2 transform -translate-x-1/2 space-x-2">
        {[0, 1, 2].map((index) => (
          <button
            key={index}
            className={`w-4 h-4 rounded-full ${
              currentSlide === index ? "bg-gray-900" : "bg-gray-300"
            }`}
            onClick={() => setCurrentSlide(index)}
          />
        ))}
      </div>
      </div>
      {/* <button className="absolute top-1/2 left-4 transform -translate-y-1/2 p-2 rounded-full bg-white shadow-md" onClick={prevSlide}>
        &lt;
      </button>
      <button className="absolute top-1/2 right-4 transform -translate-y-1/2 p-2 rounded-full bg-white shadow-md" onClick={nextSlide}>
        &gt;
      </button> */}

      <div
        className="hidden overflow-hidden rounded-md showing_display h-auto"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <div
          className="flex transition-transform duration-300 transform translate-x-full"
          style={{ transform: `translateX(-${currentSlide * 100}%)` }}
        >
          <div className="w-full flex flex-shrink-0">
            <div className="py-8 pl-4">
              <Link to={`/blogs/${carouselArray[0].title.replace(/ /g, "+")}`} className="">
                <Resource_card
                  image={carouselArray[0].image}
                  title={carouselArray[0].title}
                  author={""}
                  readingTime={""}
                />
              </Link>
            </div>
          </div>
          <div className="w-full flex flex-shrink-0">
            <div className="py-8 pl-4">
              <Link to={`/blogs/${carouselArray[1].title.replace(/ /g, "+")}`} className="">
                <Resource_card
                  image={carouselArray[1].image}
                  title={carouselArray[1].title}
                  author={""}
                  readingTime={""}
                />
              </Link>
            </div>
          </div>
          <div className="w-full flex flex-shrink-0">
            <div className="py-8 pl-4">
              <Link to={`/blogs/${carouselArray[2].title.replace(/ /g, "+")}`} className="">
                <Resource_card
                  image={carouselArray[2].image}
                  title={carouselArray[2].title}
                  author={""}
                  readingTime={""}
                />
              </Link>
            </div>
          </div>
          <div className="w-full flex flex-shrink-0">
            <div className="py-8 pl-4">
              <Link to={`/blogs/${carouselArray[3].title.replace(/ /g, "+")}`} className="">
                <Resource_card
                  image={carouselArray[3].image}
                  title={carouselArray[3].title}
                  author={""}
                  readingTime={""}
                />
              </Link>
            </div>
          </div>
          <div className="w-full flex flex-shrink-0">
            <div className="py-8 pl-4">
              <Link to={`/blogs/${carouselArray[4].title.replace(/ /g, "+")}`} className="">
                <Resource_card
                  image={carouselArray[4].image}
                  title={carouselArray[4].title}
                  author={""}
                  readingTime={""}
                />
              </Link>
            </div>
          </div>
          <div className="w-full flex flex-shrink-0">
            <div className="py-8 pl-4">
              <Link to={`/blogs/${carouselArray[5].title.replace(/ /g, "+")}`} className="">
                <Resource_card
                  image={carouselArray[5].image}
                  title={carouselArray[5].title}
                  author={""}
                  readingTime={""}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden absolute showing_display left-1/2 transform -translate-x-1/2  space-x-2">
        {[0, 1, 2, 3, 4, 5].map((index) => (
          <button
            key={index}
            className={`w-4 h-4 rounded-full ${
              currentSlide === index ? "bg-gray-900" : "bg-gray-300"
            }`}
            onClick={() => setCurrentSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};
