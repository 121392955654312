import React from 'react'
import image from "../assets/image52.png"
import Selfimage from "../assets/SelfImage.png"
import { Link } from 'react-router-dom'

export default function
  () {
  return (
    <section id='SelfVerification' className='section'>
      <div className='boxy'>
        <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1">
          <div className="part-1 boxy">
            <div className='ele-center-flex justify-end'>
              <div className="text-left card-mobile-props lg:ml-48">
                <h1>
                  <span className='Self'>Self-</span>
                  <span className='Verification'>Verification</span>
                </h1>
                <p className='para1'>
                  Empower your employees with Hirestar.io's intuitive portal for independent credential verification.
                  <span className='para2'> Secure document uploads and real-time tracking</span> put individuals in control, ensuring a seamless verification journey.
                </p>
                <div className='mt-4'>
                  <span className='btn'>
                    <Link to={"/self-verification"} class='learnMore_btn'>Learn More &gt;</Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="part-2 boxy">
            <div className="ele-center-flex justify-start">
              <div className="box-1">
                <div className="mx-auto mt-4 mb-3 parent-1 "></div>
                <div className=" parent-2 ">
                  <div className="relative card m-4">
                    {/* Added alt attribute */}
                    <img loading="lazy" src={image} alt='Self-Verification' className='SelfImage' width="900" height="900" />
                    <div className="parent-3 absolute p-2 flex space-x-3">
                      {/* Added alt attribute */}
                      <img loading="lazy" src={Selfimage} alt="Selfimage" width="200" height="100" />
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="102" viewBox="0 0 22 102" fill="none" loading="lazy">
                        {/* SVG Path */}
                        <path d="M7 2.49085C5.67392 2.49085 4.40215 3.01625 3.46447 3.95147C2.52678 4.88669 2 6.15513 2 7.47773C2 8.80034 2.52678 10.0688 3.46447 11.004C4.40215 11.9392 5.67392 12.4646 7 12.4646H15C16.3261 12.4646 17.5979 11.9392 18.5355 11.004C19.4732 10.0688 20 8.80034 20 7.47773C20 6.15513 19.4732 4.88669 18.5355 3.95147C17.5979 3.01625 16.3261 2.49085 15 2.49085H7ZM7 0.496094H15C15.9193 0.496094 16.8295 0.676679 17.6788 1.02754C18.5281 1.3784 19.2997 1.89266 19.9497 2.54097C20.5998 3.18927 21.1154 3.95892 21.4672 4.80597C21.8189 5.65303 22 6.56089 22 7.47773C22 8.39458 21.8189 9.30244 21.4672 10.1495C21.1154 10.9965 20.5998 11.7662 19.9497 12.4145C19.2997 13.0628 18.5281 13.5771 17.6788 13.9279C16.8295 14.2788 15.9193 14.4594 15 14.4594H7C6.08075 14.4594 5.1705 14.2788 4.32122 13.9279C3.47194 13.5771 2.70026 13.0628 2.05025 12.4145C1.40024 11.7662 0.884626 10.9965 0.532843 10.1495C0.18106 9.30244 0 8.39458 0 7.47773C0 6.56089 0.18106 5.65303 0.532843 4.80597C0.884626 3.95892 1.40024 3.18927 2.05025 2.54097C2.70026 1.89266 3.47194 1.3784 4.32122 1.02754C5.1705 0.676679 6.08075 0.496094 7 0.496094ZM7 10.4699C6.20435 10.4699 5.44129 10.1546 4.87868 9.59349C4.31607 9.03236 4 8.2713 4 7.47773C4 6.68417 4.31607 5.92311 4.87868 5.36198C5.44129 4.80084 6.20435 4.4856 7 4.4856C7.79565 4.4856 8.55871 4.80084 9.12132 5.36198C9.68393 5.92311 10 6.68417 10 7.47773C10 8.2713 9.68393 9.03236 9.12132 9.59349C8.55871 10.1546 7.79565 10.4699 7 10.4699Z" fill="#383838" />
                        <path d="M7 31.4147C5.67392 31.4147 4.40215 31.9401 3.46447 32.8753C2.52678 33.8105 2 35.079 2 36.4016C2 37.7242 2.52678 38.9926 3.46447 39.9278C4.40215 40.863 5.67392 41.3884 7 41.3884H15C16.3261 41.3884 17.5979 40.863 18.5355 39.9278C19.4732 38.9926 20 37.7242 20 36.4016C20 35.079 19.4732 33.8105 18.5355 32.8753C17.5979 31.9401 16.3261 31.4147 15 31.4147H7ZM7 29.4199H15C15.9193 29.4199 16.8295 29.6005 17.6788 29.9514C18.5281 30.3022 19.2997 30.8165 19.9497 31.4648C20.5998 32.1131 21.1154 32.8828 21.4672 33.7298C21.8189 34.5769 22 35.4847 22 36.4016C22 37.3184 21.8189 38.2263 21.4672 39.0733C21.1154 39.9204 20.5998 40.69 19.9497 41.3383C19.2997 41.9866 18.5281 42.5009 17.6788 42.8518C16.8295 43.2026 15.9193 43.3832 15 43.3832H7C6.08075 43.3832 5.1705 43.2026 4.32122 42.8518C3.47194 42.5009 2.70026 41.9866 2.05025 41.3383C1.40024 40.69 0.884626 39.9204 0.532843 39.0733C0.18106 38.2263 0 37.3184 0 36.4016C0 35.4847 0.18106 34.5769 0.532843 33.7298C0.884626 32.8828 1.40024 32.1131 2.05025 31.4648C2.70026 30.8165 3.47194 30.3022 4.32122 29.9514C5.1705 29.6005 6.08075 29.4199 7 29.4199ZM7 39.3937C6.20435 39.3937 5.44129 39.0785 4.87868 38.5173C4.31607 37.9562 4 37.1951 4 36.4016C4 35.608 4.31607 34.8469 4.87868 34.2858C5.44129 33.7247 6.20435 33.4094 7 33.4094C7.79565 33.4094 8.55871 33.7247 9.12132 34.2858C9.68393 34.8469 10 35.608 10 36.4016C10 37.1951 9.68393 37.9562 9.12132 38.5173C8.55871 39.0785 7.79565 39.3937 7 39.3937Z" fill="#383838" />
                        <path d="M7 60.3385C5.67392 60.3385 4.40215 60.8639 3.46447 61.7991C2.52678 62.7344 2 64.0028 2 65.3254C2 66.648 2.52678 67.9164 3.46447 68.8516C4.40215 69.7869 5.67392 70.3123 7 70.3123H15C16.3261 70.3123 17.5979 69.7869 18.5355 68.8516C19.4732 67.9164 20 66.648 20 65.3254C20 64.0028 19.4732 62.7344 18.5355 61.7991C17.5979 60.8639 16.3261 60.3385 15 60.3385H7ZM7 58.3438H15C15.9193 58.3437 16.8295 58.5243 17.6788 58.8752C18.5281 59.2261 19.2997 59.7403 19.9497 60.3886C20.5998 61.0369 21.1154 61.8066 21.4672 62.6536C21.8189 63.5007 22 64.4085 22 65.3254C22 66.2422 21.8189 67.1501 21.4672 67.9971C21.1154 68.8442 20.5998 69.6138 19.9497 70.2622C19.2997 70.9105 18.5281 71.4247 17.6788 71.7756C16.8295 72.1264 15.9193 72.307 15 72.307H7C6.08075 72.307 5.1705 72.1264 4.32122 71.7756C3.47194 71.4247 2.70026 70.9105 2.05025 70.2622C1.40024 69.6138 0.884626 68.8442 0.532843 67.9971C0.18106 67.1501 0 66.2422 0 65.3254C0 64.4085 0.18106 63.5007 0.532843 62.6536C0.884626 61.8066 1.40024 61.0369 2.05025 60.3886C2.70026 59.7403 3.47194 59.2261 4.32122 58.8752C5.1705 58.5243 6.08075 58.3437 7 58.3438ZM7 68.3175C6.20435 68.3175 5.44129 68.0023 4.87868 67.4411C4.31607 66.88 4 66.119 4 65.3254C4 64.5318 4.31607 63.7708 4.87868 63.2096C5.44129 62.6485 6.20435 62.3333 7 62.3333C7.79565 62.3333 8.55871 62.6485 9.12132 63.2096C9.68393 63.7708 10 64.5318 10 65.3254C10 66.119 9.68393 66.88 9.12132 67.4411C8.55871 68.0023 7.79565 68.3175 7 68.3175Z" fill="#383838" />
                        <path d="M7 89.2623C5.67392 89.2623 4.40215 89.7877 3.46447 90.723C2.52678 91.6582 2 92.9266 2 94.2492C2 95.5718 2.52678 96.8403 3.46447 97.7755C4.40215 98.7107 5.67392 99.2361 7 99.2361H15C16.3261 99.2361 17.5979 98.7107 18.5355 97.7755C19.4732 96.8403 20 95.5718 20 94.2492C20 92.9266 19.4732 91.6582 18.5355 90.723C17.5979 89.7877 16.3261 89.2623 15 89.2623H7ZM7 87.2676H15C15.9193 87.2676 16.8295 87.4482 17.6788 87.799C18.5281 88.1499 19.2997 88.6641 19.9497 89.3125C20.5998 89.9608 21.1154 90.7304 21.4672 91.5775C21.8189 92.4245 22 93.3324 22 94.2492C22 95.1661 21.8189 96.0739 21.4672 96.921C21.1154 97.768 20.5998 98.5377 19.9497 99.186C19.2997 99.8343 18.5281 100.349 17.6788 100.699C16.8295 101.05 15.9193 101.231 15 101.231H7C6.08075 101.231 5.1705 101.05 4.32122 100.699C3.47194 100.349 2.70026 99.8343 2.05025 99.186C1.40024 98.5377 0.884626 97.768 0.532843 96.921C0.18106 96.0739 0 95.1661 0 94.2492C0 93.3324 0.18106 92.4245 0.532843 91.5775C0.884626 90.7304 1.40024 89.9608 2.05025 89.3125C2.70026 88.6641 3.47194 88.1499 4.32122 87.799C5.1705 87.4482 6.08075 87.2676 7 87.2676ZM7 97.2413C6.20435 97.2413 5.44129 96.9261 4.87868 96.365C4.31607 95.8038 4 95.0428 4 94.2492C4 93.4557 4.31607 92.6946 4.87868 92.1335C5.44129 91.5723 6.20435 91.2571 7 91.2571C7.79565 91.2571 8.55871 91.5723 9.12132 92.1335C9.68393 92.6946 10 93.4557 10 94.2492C10 95.0428 9.68393 95.8038 9.12132 96.365C8.55871 96.9261 7.79565 97.2413 7 97.2413Z" fill="#383838" />
                        <path d="M7 29.4199H15C15.9193 29.4199 16.8295 29.6005 17.6788 29.9514C18.5281 30.3022 19.2997 30.8165 19.9497 31.4648C20.5998 32.1131 21.1154 32.8828 21.4672 33.7298C21.8189 34.5769 22 35.4847 22 36.4016C22 37.3184 21.8189 38.2263 21.4672 39.0733C21.1154 39.9204 20.5998 40.69 19.9497 41.3383C19.2997 41.9866 18.5281 42.5009 17.6788 42.8518C16.8295 43.2026 15.9193 43.3832 15 43.3832H7C6.08075 43.3832 5.1705 43.2026 4.32122 42.8518C3.47194 42.5009 2.70026 41.9866 2.05025 41.3383C1.40024 40.69 0.884626 39.9204 0.532843 39.0733C0.18106 38.2263 0 37.3184 0 36.4016C0 35.4847 0.18106 34.5769 0.532843 33.7298C0.884626 32.8828 1.40024 32.1131 2.05025 31.4648C2.70026 30.8165 3.47194 30.3022 4.32122 29.9514C5.1705 29.6005 6.08075 29.4199 7 29.4199ZM15 39.3937C15.7956 39.3937 16.5587 39.0785 17.1213 38.5173C17.6839 37.9562 18 37.1951 18 36.4016C18 35.608 17.6839 34.8469 17.1213 34.2858C16.5587 33.7247 15.7956 33.4094 15 33.4094C14.2044 33.4094 13.4413 33.7247 12.8787 34.2858C12.3161 34.8469 12 35.608 12 36.4016C12 37.1951 12.3161 37.9562 12.8787 38.5173C13.4413 39.0785 14.2044 39.3937 15 39.3937Z" fill="black" />
                        <path d="M7 58.3438H15C15.9193 58.3437 16.8295 58.5243 17.6788 58.8752C18.5281 59.2261 19.2997 59.7403 19.9497 60.3886C20.5998 61.0369 21.1154 61.8066 21.4672 62.6536C21.8189 63.5007 22 64.4085 22 65.3254C22 66.2422 21.8189 67.1501 21.4672 67.9971C21.1154 68.8442 20.5998 69.6138 19.9497 70.2622C19.2997 70.9105 18.5281 71.4247 17.6788 71.7756C16.8295 72.1264 15.9193 72.307 15 72.307H7C6.08075 72.307 5.1705 72.1264 4.32122 71.7756C3.47194 71.4247 2.70026 70.9105 2.05025 70.2622C1.40024 69.6138 0.884626 68.8442 0.532843 67.9971C0.18106 67.1501 0 66.2422 0 65.3254C0 64.4085 0.18106 63.5007 0.532843 62.6536C0.884626 61.8066 1.40024 61.0369 2.05025 60.3886C2.70026 59.7403 3.47194 59.2261 4.32122 58.8752C5.1705 58.5243 6.08075 58.3437 7 58.3438ZM15 68.3175C15.7956 68.3175 16.5587 68.0023 17.1213 67.4411C17.6839 66.88 18 66.119 18 65.3254C18 64.5318 17.6839 63.7708 17.1213 63.2096C16.5587 62.6485 15.7956 62.3333 15 62.3333C14.2044 62.3333 13.4413 62.6485 12.8787 63.2096C12.3161 63.7708 12 64.5318 12 65.3254C12 66.119 12.3161 66.88 12.8787 67.4411C13.4413 68.0023 14.2044 68.3175 15 68.3175Z" fill="black" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

}
