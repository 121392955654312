import React, { useEffect } from "react";
import { Resource_card } from "../Resources_dropdown/Resource_card";
import { Link } from "react-router-dom";
import { Side_bar } from "./Side_bar";
import { Carousel } from "./Carousel";
import { Explore_categories } from "../Case_Study/Explore_categories";
import { Help } from "../Case_Study/Help";
// import { blogArray } from "../../blog";

export const BlogPage = ({ blogArray }) => {
  // console.log(blogArray);
  const carouselArray = blogArray.slice(0, 6);

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <div className=" mx-auto bg-gradient-to-l from-sky-100 FontFamily">
      <div className="container mx-auto">
        <div className="container  grid-cols-1 text-center p-4 px-8">
          <div className="container w-full mx-auto">
            <div className=" py-28 text-center">
              <div>
                <p className="text-6xl font-bold text-cyan-600">Blogs</p>
              </div>
              <div className="container mt-4">
                <p className="text-xl font-semibold">
                  Everything <strong>you need</strong> to know about{" "}
                  <strong>hiring</strong> around the <strong>globe</strong>.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container border-2 border-indigo-500 flex justify-end px-8 items-center bg-gradient-to-l from-sky-100 px-8">
        <img
          src="https://assets-global.website-files.com/63f46c1b2a45bbaf7fb7d1e6/641182a270f96465362414b9_search.svg"
          alt=""
          className="w-6 h-6 -mr-1"
        />
        <input
          type="text"
          className="focus:border-none outline-none text-[inherit] w-60 bg-transparent ml-2 h-[38px] self-center text-lg"
          placeholder="Search..."
        />
      </div> */}
        <div className="container px-8 bg-gradient-to-l from-sky-100 py-12">
          <Carousel carouselArray={carouselArray} />
        </div>
        <div className="container px-8 flex flex-wrap py-16 bg-gradient-to-l from-sky-100 lg:flex-row md:flex-col sm:flex-col">
          {/* <div className="sideBar z-10 container sticky top-0 lg:w-1/4 md:w-full sm:w-full  bg-white">
          <Side_bar />
        </div> */}
          <div className="lg:full h-auto md:w-full sm:w-full grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
            {blogArray.map((blog) => (
              <div className="shadow-md m-4 rounded-lg" key={blog.title}>
                <Link
                  to={`/blogs/${blog.title.replace(/ /g, "+")}`}
                >
                  <Resource_card
                    key={blog.title}
                    image={blog.image}
                    title={blog.title}
                    author={blog.author}
                    fontTitle={"text-xl"}
                    fontAuthor={"text-md"}
                    readingTime={blog.readingTime}
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div className="container mt-8 px-4">
          <Explore_categories />
        </div>
        <div className="container bg-gradient-to-l from-sky-100 mt-8">
          <Help />
        </div>
      </div>
    </div>
  );
};
