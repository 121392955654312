import React, { useEffect } from "react";
import Section_1 from "../Section_1";
import Card_1 from "../../Cards/Card_1";
import Card_2 from "../../Cards/Card_2";
import Cards3 from "../../Cards/Card_3";
import Section_3 from "../Section_3";
import { Services } from "../Services";
import { Clients } from "../Clients";
import { Testimonials } from "../Testimonials";

export const LandingPage = () => {
  return (
    <div className="">
      <Section_1 />
      <section className="relative mb-12">
        <Card_1 />
        <Card_2 />
        <Cards3 />

      </section>
      <Section_3 />
      <Services />
      <Clients />
      <Testimonials />
    </div>
  );
};
