import React from 'react'
import Identity from "../assets/Service_PNG/Identity.png"
import crimecheck from "../assets/Service_PNG/crimecheck.png";
import degree from "../assets/Service_PNG/degree.png";
import employment from "../assets/Service_PNG/employment.png";
import financial from "../assets/Service_PNG/financial.png";
import Healthcare from "../assets/Service_PNG/Healthcare.png";
import Background from "../assets/Service_PNG/Background.png";

import "../Styles/Services.css";
import { Link } from 'react-router-dom';
export const Services = () => {
    return (
     
                <section id="ServicesSection">
            <h1 className='service-heading'>Our <span style={{ color: "#0D6EB5" }}>Services</span> </h1>
            <div className="container mx-auto">
                <div className="w-11/12 m-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 grid-flow-row gap-8 p-10">
                    <article>
                        <Link to="/industry-support">
                            <div className='p-5 rounded-lg bg-color h-[220px]'>
                                <div>
                                    <img src={Identity} alt='Identity Verification' width="60" height="50" />
                                </div>
                                <div className='pt-4'>
                                    <h2 className='service-card-heading'>Identity Verification</h2>
                                    <p className='service_description'>Comprehensive verification of identity documents to enhance security.</p>
                                </div>
                            </div>
                        </Link>
                    </article>
                    <article>
                        <Link to="/industry-support" >
                            <div className='p-5 rounded-lg bg-color h-[220px]'>
                                <div >
                                    <img src={degree} alt='Degree' width="60" height="50" />
                                </div>
                                <div className='pt-4'>
                                    <h2 className='service-card-heading'>Degree Verification</h2>
                                    <p className='service_description'>Validation of academic qualifications for a reliable and skilled workforce.</p>
                                </div>
                            </div>
                        </Link>
                    </article>
                    <article>
                        <Link to="/industry-support" >
                            <div className='p-5 rounded-lg bg-color h-[220px]'>
                                <div className='relative p-5'>
                                    <img className='relative z-10 bottom-3 right-2' src={employment} alt='employment' width="35" height="50" />
                                    <img className='absolute inset-0' width="60" height="50" src={Background} alt='Background' />
                                </div>
                                <div>
                                    <h2 className='service-card-heading'>Employment Verification</h2>
                                    <p className='service_description'>Rigorous screening to confirm and validate professional backgrounds.</p>
                                </div>
                            </div>
                        </Link>
                    </article>
                    <article>
                        <Link to="/industry-support" >
                            <div className='p-5 rounded-lg bg-color h-[220px]'>
                                <div >
                                    <img src={Healthcare} alt='Healthcare' width="60" height="50" />
                                </div>
                                <div className='pt-4'>
                                    <h2 className='service-card-heading'>Drug Test</h2>
                                    <p className='service_description'>Ensuring the integrity of your workforce in sensitive healthcare sectors.</p>
                                </div>
                            </div>
                        </Link>
                    </article>
                    <article>
                        <Link to="/industry-support" >
                            <div className='p-5 rounded-lg bg-color h-[220px]'>
                                <div className='relative p-5'>
                                    <img className='relative z-10 mr-15  bottom-3 right-2' src={financial} alt='financial' width="35" height="50" />
                                    <img className='absolute inset-0' width="60" height="50" src={Background} alt='Background' />
                                </div>
                                <div>
                                    <h2 className='service-card-heading' >Financial Integrity Check</h2>
                                    <p className='service_description'>Verification of financial integrity for safeguarding financial interests.</p>
                                </div>
                            </div>
                        </Link>
                    </article>
                    <article>
                        <Link to="/industry-support" >
                            <div className='p-5 rounded-lg bg-color h-[220px]'>
                                <div>
                                    <img src={crimecheck} alt='crimecheck' width="60" height="50" />
                                </div>
                                <div className='pt-4'>
                                    <h2 className='service-card-heading'>Crime Check</h2>
                                    <p className='service_description'>Screening for criminal records to maintain a safe and trustworthy workplace.</p>
                                </div>
                            </div>
                        </Link>
                    </article>
                    {/* Repeat similar structure for other services */}
                </div>
            </div>
        </section>

    )
}
