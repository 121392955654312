import React from 'react'
import half_image from '../assets/half-Circle.png'
import Hero_img from './Hero_img'
import { Link, useLocation } from 'react-router-dom'
import { useEffect } from 'react';
export default function Section_1() {


  let hero_page_height = {
    height: "720px"
  }

  return (
    // Encapsulating section for better semantics
    <section className='section-1 mt-[5%]'>
      <div className="grid lg:grid-cols-2 section-1-box h-full md:grid-cols-1 sm:grid-cols-1">
        <div className="section-1-box">
          {/* Added descriptive alt attribute */}
          <img src={half_image} alt="Unlock Excellence in Background Verification" className="half_image absolute" />
          <div className='relative flex justify-center h-full items-center mx-4'>
            <div className='hero-box'>
              {/* Replaced h1 content with plain text */}
              <h1 ><span className='Self'>Unlock </span> <span className='Verification'> Excellence<br />in Background Verification</span> </h1>
              {/* Changed div to p for semantic HTML */}
              <p className='para3'>Elevate Your Workforce with<br />Blockchain-Driven Background<br />Verification.</p>
              <div className="text-center">
                {/* Wrapped button inside a Link component */}
                <Link to="/contact-us">
                  {/* Improved button text */}
                  <button className='custom-button hero-button'>Request Demo</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="section-1-box sm:order-1 md:order-1">
          <Hero_img />
        </div>
      </div>
    </section>
  );

}
