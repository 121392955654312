import React from "react";
import resourceImage from "../../assets/resourceImage.png"

export const DropDownCard = () => {
  return (
    <div className="grid gap-y-1">
      <div className="">
        <p className="text-lg font-bold text-sky-600">Featured Resources</p>
      </div>
      <div className="w-[272px] h-[138px]">
        <img src={resourceImage} className="" alt=""/>
      </div>
      <div className="">
        <p className="text-lg font-bold">Mastering Background Verification in the Digital Age</p>
      </div>
      <div className="">
        <p className="text-md font-normal">
          An in-depth guide on leveraging technology for reliable background
          verification in modern hiring.
        </p>
      </div>
    </div>
  );
};
