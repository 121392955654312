import React, { useEffect } from "react";
import { videoArray } from "../../Video";
import { Link } from "react-router-dom";
import { VideoCard } from "./VideoCard";
export const VideoPage = () => {
  // console.log(videoArray);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="mx-auto bg-gradient-to-l from-sky-100 FontFamily">
      <div className="container mx-auto">
        <div className="container  grid-cols-1 text-center p-4 px-8">
          <div className="container w-full mx-auto">
            <div className=" py-28 text-center">
              <div>
                <p className="text-6xl font-bold text-cyan-600">Videos</p>
              </div>
              <div className="container mt-4">
                <p className="text-xl font-semibold">
                  Everything <strong>you need</strong> to know about{" "}
                  <strong>background verification</strong> through{" "}
                  <strong>videos</strong>.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container px-8 flex flex-wrap py-16 bg-gradient-to-l from-sky-100 lg:flex-row md:flex-col sm:flex-col">
          {/* <div className="sideBar z-10 container sticky top-0 lg:w-1/4 md:w-full sm:w-full  bg-white">
          <Side_bar />
        </div> */}
          <div className="lg:full h-auto md:w-full sm:w-full grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
            {videoArray.map((vid) => (
              <div className="rounded-lg m-4" key={vid.title}>
                <Link>
                  <VideoCard title={vid.title} videoUrl={vid.videoUrl} />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
