import React, { useEffect, useRef, useState } from 'react';
import SvgShape from "../../assets/SSVG-SHAPES/graph (1).png"
import employee from '../../assets/header Image/istockphoto-1180926773-1024x1024-removebg-preview.png'
import "./ContactUs.css";
import captchaimg from "../../assets/captchaimg.jpg"
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DemoFormValidation } from '../../Functions/function';
import { useNavigate } from 'react-router';

export const ContactUs = () => {
    const [formdata, setFormdata] = useState({});
    const [captcha, setCaptcha] = useState("");
    const [inputcaptcha, setInputCaptcha] = useState("");
    const [iscorrect, setIsCorrect] = useState(true);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const navigate = useNavigate()


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function randomCaptcha(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const handleChange = (e) => {
        // const key_name = e.target.name;
        // setFormdata({ ...formdata, [key_name]: e.target.value })
        const keyName = e.target.name;
        const value = e.target.value;

        // Update the form data state
        setFormdata({ ...formdata, [keyName]: value });

        // Check if the email is in the correct format
        if (keyName === 'company_email') {
            setIsValidEmail(/^[\w-\.]+@gmail.com$/.test(value));
        }

    }


    const DemoSubmit = (e) => {
        e.preventDefault();
        formdata['captcha'] = inputcaptcha
        const validation_error = DemoFormValidation(formdata);
        if (validation_error) {
            toast.error(validation_error, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                className: "toast-error",
            });
            return;
        }
        delete formdata["captcha"]
        if (captcha == inputcaptcha) {
            axios.post(`${process.env.REACT_APP_OFFERS_URL}/offers/demo/request`, formdata)
                .then((res) => {
                    console.log(res.data)
                    toast.success(res.data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        className: "toast-success"
                    });
                    setTimeout(() => {
                        navigate("/")
                    }, 4000)
                })
                .catch((err) => {
                    // console.log(err)
                    toast.error(err.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        className: "toast-success"
                    });
                })
            // console.log(formdata)
        } else {
            setIsCorrect(false)
        }
    }

    useEffect(() => {
        setCaptcha(randomCaptcha(6));
    }, []);

    return (
        <>
            <ToastContainer />
            <div>
                <div className='container lg-auto mt-24 lg:mt-20 sm:mt-24' >
                    <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 justify-between items-center">
                        {/* <div> */}
                        <form onSubmit={DemoSubmit}>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:ml-8">
                                <div className="px-4 mb-4">

                                    <label className="block text-gray-700 text-sm mb-2" htmlFor="firstName">
                                        First Name <span className='text-xl text-red-500'>*</span>
                                    </label>
                                    <input
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        id="firstName"
                                        type="text"
                                        placeholder="First Name"
                                        name='first_name'
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="px-4 mb-4">
                                    <label className="block text-gray-700 text-sm mb-2" htmlFor="lastName">
                                        Last Name <span className='text-xl text-red-500'>*</span>
                                    </label>
                                    <input
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        id="lastName"
                                        type="text"
                                        placeholder="Last Name"
                                        name='last_name'
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="px-4 mb-4">
                                    <label className="block text-gray-700 text-sm mb-2" htmlFor="companyEmail">
                                        Company Email <span className='text-xl text-red-500'>*</span>
                                    </label>
                                    <input
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        id="companyEmail"
                                        type="email"
                                        placeholder="Company Email"
                                        name='company_email'
                                        onChange={handleChange}
                                    />
                                    {!isValidEmail && <p className='text-xs mb-2 text-red-500'>Email address is not in correct format for Gmail</p>}
                                </div>
                                <div className="px-4 mb-4">
                                    <label className="block text-gray-700 text-sm mb-2" htmlFor="companyName">
                                        Company Name <span className='text-xl text-red-500'>*</span>
                                    </label>
                                    <input
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        id="companyName"
                                        type="text"
                                        placeholder="Company Name"
                                        name='company_name'
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="md:col-span-2 px-4 mb-4">
                                    <label className="block text-gray-700 text-sm mb-2" htmlFor="mobileNumber">
                                        Mobile Number <span className='text-xl text-red-500'>*</span>
                                    </label>
                                    <p className="text-xs mb-2">include dialing code, e.g. +91 for India</p>
                                    <input
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        id="mobileNumber"
                                        type="tel"
                                        placeholder="Mobile Number"
                                        name='mobile_number'
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="px-4 mb-4">
                                    <label className="block text-gray-700 text-sm mb-2" htmlFor="hires">
                                        How many hires are you planning to make in the next 12 months? <span className='text-xl text-red-500'>*</span>
                                    </label>
                                    <select id="hires" name='hiring_forecast' onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                        <option value="" disabled selected>Please Select</option>
                                        <option value="1-30">1-30</option>
                                        <option value="31-200">31-200</option>
                                        <option value="More than 200">More than 200</option>
                                    </select>
                                </div>
                                <div className="px-4 mb-4">
                                    <label htmlFor="employees" className="block text-gray-700 text-sm mb-2">
                                        Number of employees in your organization <span className='text-xl text-red-500'>*</span>
                                    </label>
                                    <select id="employees" name='number_of_employees' onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                        <option value="" disabled selected>Please Choose</option>
                                        <option value="Less than 50">Less than 50</option>
                                        <option value="50 - 499">50 - 499</option>
                                        <option value="More than 500">More than 500</option>
                                    </select>
                                </div>
                                <div class="px-4 mb-4">
                                    <h6 class="h6"> Please verify you are a human<span className='text-xl text-red-500'>*</span></h6>
                                    <p width="400" height="50" className='p-2 text-center text-xl' style={{ backgroundImage: `url(${captchaimg})`, fontSize: "30px", backgroundColor: "gray", color: "black", fontWeight: "bold" }}>{captcha}</p>
                                    <p style={{ display: iscorrect ? 'none' : 'block', color: 'red', fontSize: "12px" }} >*captch is wrong*</p>
                                    <input type="text" name="captcha" onChange={(e) => { setInputCaptcha(e.target.value) }}
                                        placeholder="captcha" className="mt-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                </div>
                                <div className='rounded-lg md:col-span-2 px-4 mb-4 w-2/5 p-2 '>
                                    <button type='submit' className='w-full m-auto h-auto rounded-lg md:col-span-2 text-white font-semibold border-sky-600 Speak-btn pr-4  mt-3 bg-gray-50 border  text-sm   block  p-2.5 placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500 submit' >Submit</button>
                                </div>

                            </div>
                        </form>
                        {/* </div> */}

                        <div className="w-full mb-auto" >
                            <div className='relative '>
                                <div className='p-2'>
                                    <img src={employee} alt="" className='w-[80%] absolute left-[10%] lg:left-[12%] sm:top-[10%] sm:left-[10%]' />
                                    <img src={SvgShape} alt="" className='w-[80%]' />
                                </div>
                            </div>
                        </div>
                    </div>

                </div >

            </div >
        </>
    )
}
