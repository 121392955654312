import React from 'react'
import { FaCheck } from "react-icons/fa6";

import card2 from "../assets/card2.webp"

import arrow from "../assets/servicesImages/arrow.png"

export default function
    Card1() {
/*     const industriesArray = [
        { title: "Fintech" },
        { title: "HR, Recruitment" },
        { title: "Tech" },
        { title: "BPO" },
        { title: "EOR" },
    ]; */
    return (
        <div>
            {/* Industry */}
            <div className='boxy mt-12'>

                <div className="grid lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-2">

                 
                    <div className="part-2 boxy">
                        <div className="ele-center-flex2 mt-40 justify-center">

                            <div className=" m-4  w-[70%] font-semibold text-lg">
                                <div className='from-neutral-900 text-5xl font-bold '><h1 className='text-wrap '>Industry</h1></div>
                                <div className='mt-10 '>Fintech innovates finance; HR optimizes talent; Tech drives digital transformation; BPO streamlines operations. Each sector offers specialized solutions, empowering businesses to excel in today's dynamic landscape.</div>

                            </div>
                        </div>
                    </div>
                    <div className="part-1 boxy">
                        <div className='ele-center-flexs justify-start '>
                            <div className="grid grid-cols-1 lg:gap-3 mx-auto ">
                                <div class="bg-white flex-grow flex-col w-[170px] shrink-0 max-w-full h-[47px]  rounded-[40px] border-2 border-solid border-black max-md:mb-10 p-2 font-semibold shadow-2xl">
                                    <p class="flex items-center gap-2 text-center"><FaCheck className='text-red-500'/> Fintech</p>
                                </div>
                                <div class="bg-white flex-grow flex-col w-[170px] shrink-0 max-w-full h-[47px] rounded-[40px] border-2 border-solid border-black max-md:mb-10 p-2 font-semibold shadow-2xl">
                                    <p class="flex items-center gap-2"><FaCheck className='text-red-500' /> HR, Recruitment</p>
                                </div>
                                <div class="bg-white flex-grow flex-col w-[170px] shrink-0 max-w-full h-[47px]  rounded-[40px] border-2 border-solid border-black max-md:mb-10 p-2 font-semibold shadow-2xl">
                                    <p class="flex items-center gap-2"><FaCheck className='text-red-500' /> Tech</p>
                                </div>
                                <div class="bg-white flex-grow flex-col w-[170px] shrink-0 max-w-full h-[47px] rounded-[40px] border-2 border-solid border-black max-md:mb-10 p-2 font-semibold shadow-2xl">
                                    <p class="flex items-center gap-2"><FaCheck className='text-red-500' /> BPO</p>
                                </div>
                                <div class="bg-white flex-grow flex-col w-[170px] shrink-0 max-w-full h-[47px] rounded-[40px] border-2 border-solid border-black max-md:mb-10 p-2 font-semibold shadow-2xl">
                                    <p class="flex items-center gap-2"><FaCheck className='text-red-500' /> EOR</p>
                                </div>
                           
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/* API */}
            <div className='boxy mt-12'>

                <div className="grid lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-2">

                    <div className="part-1 boxy">


                            

                            <div className='ele-center-flexs justify-center '>
                                <div className='p-8'>
                                    <img src={card2} alt="" />
                                </div>
                            </div>
                    </div>
                    <div className="part-2 boxy">
                    <div className="ele-center-flex2 mt-40 justify-center">

                        <div className=" m-4  w-[70%] font-semibold text-lg">
                            <div className='from-neutral-900 text-5xl font-bold '><h1 className='text-wrap '>Hirestar API</h1></div>
                                <div className='mt-10'>A speedy and reliable API which integrates Veremark directly and seamlessly into the hiring or talent platform of your choice.</div>

                        </div>
                    </div>
                    </div>

                </div>

            </div>
        </div>

    )
}
