import React from 'react'
import SvgShape from "../assets/SSVG-SHAPES/graph (1).png"
import BGV from '../assets/header Image/verification1.png'
import { Link } from 'react-router-dom'


export default function Hero_img() {
  return (
    <div>


      <div>
        <div className='boxy w-11/12 m-auto'>
          <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1">
            <div className="part-1 boxy">
              <div className="ele-center-flex2 mt-28 lg:mt-40 sm:mt-28">
                <div className=" m-4 ">
                  <div><h1 className='text-wrap Verification'><span className='Self'>Background Verification </span> <br />Services
                  </h1></div>
                  <div className='mt-10 para1 '>
                    Unlock valuable insights into a candidate's strengths, weaknesses, and potential fit for your team, enabling informed hiring decisions and effective talent management strategies.</div>

                  <div className="flex  items-center flex-wrap">
                    <div className='mt-10'><Link to={"/contact-us"}><button className="rounded-lg text-white font-semibold Speak-btn h-12 w-[120%] Speak-btn">Speak to an expert</button></Link></div>
                  </div>
                </div>

              </div>
            </div>
            <div className="part-2 boxy">
              <div className='ele-center-flexs justify-center'>
                <div className='p-8'>
                  <img src={BGV} alt="" className='w-full shadow-2xl' />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
