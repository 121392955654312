import React, { useState } from "react";
import { NavbarProductDropdown } from "../Navbar_Product_Dropdown/Navbar_Product_Dropdown";
import { ResourcesDropdown } from "../Resources_dropdown/Resources_dropdown";
import { ServicesDropdown } from "../Services_dropdown/Services_dropdown";
import logo from "../../assets/logo.png";
import "./Header.css";
import { Link } from "react-router-dom";

export default function Header() {
  const [dropDownProduct, setDropDownProduct] = useState(false);
  const [dropDownResources, setDropDownResources] = useState(false);
  const [dropDownServices, setDropDownServices] = useState(false);
  const [isProductHovered, setIsProductHovered] = useState(false);
  const [isResourcesHovered, setIsResourcesHovered] = useState(false);
  const [isServicesHovered, setIsServicesHovered] = useState(false);
  const [sidebarDisplay, setSidebarDisplay] = useState(false);

  const handleSidebarDisplay = () => {
    setSidebarDisplay(!sidebarDisplay);
  };
  const handleCloseSidebar = () => {
    setSidebarDisplay(false);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' 
    });
  }
  return (
    <div>
      <header class="bg-white shadow-md w-full h-[72px] z-40 fixed top-0">
        <nav
          class="mx-auto flex max-w-7xl items-center justify-between p-3 lg:px-8 "
          aria-label="Global"
        >
          <div class="flex">
            <Link to="/" class="-m-1.5 p-1.5">
              <div onClick={scrollToTop} class="border bg-white self-stretch flex justify-center items-center  rounded-[50px] border-solid border-stone-300 max-md:px-5">
                <img src={logo} alt="logo" class="p-2 w-[80%]" width="100" height="100" />
              </div>

            </Link>
          </div>
          <div class="lg:hidden md:flex sm:flex" onClick={handleSidebarDisplay}>
            <button
              type="button"
              class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            >
              <span class="sr-only">Open main menu</span>
              <svg
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            </button>
          </div>
          <div class="lg:flex md:hidden lg:gap-x-12 md:gap-x-12 items-baseline hiding_navbar">
            <div class="">
                <button
                type="button"
                class="flex items-center gap-x-1 font-semibold leading-6 text-gray-900 hover:text-sky-600"
                aria-expanded="false"
              >
                <Link to={"/"} onClick={scrollToTop}>Home</Link>

              </button>

              {/* <div class="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5"></div> */}
            </div>

            <Link
              to="/products"
              class="group relative font-semibold leading-6 text-gray-900 hover:text-sky-600"
              onMouseEnter={() => setDropDownProduct(true)}
              onMouseLeave={() => setDropDownProduct(false)}
            >
              Product
              {(dropDownProduct || isProductHovered) && (
                <NavbarProductDropdown
                  setIsProductHovered={setIsProductHovered}
                />
              )}
            </Link>
            <Link
              to="/resources"
              class="font-semibold leading-6 text-gray-900 hover:text-sky-600 group relative"
              onMouseEnter={() => setDropDownResources(true)}
              onMouseLeave={() => setDropDownResources(false)}
            >
              Resources
              {(dropDownResources || isResourcesHovered) && (
                <ResourcesDropdown
                  setIsResourcesHovered={setIsResourcesHovered}
                />
              )}
            </Link>
            <Link
              to="/industry-support"
              class="group  font-semibold leading-6 text-gray-900 hover:text-sky-600 relative"
              onMouseEnter={() => setDropDownServices(true)}
              onMouseLeave={() => setDropDownServices(false)}
            >
              Services
              {(dropDownServices || isServicesHovered) && (
                <ServicesDropdown setIsServicesHovered={setIsServicesHovered} />
              )}
            </Link>
            <a href={"https://org.hirestar.io/hr_portal/"} target="_blank">
              <button class=" sign_in_button z-20">Sign in</button>
            </a>
          </div>
        </nav>
        <div
          class={`lg:hidden md:${sidebarDisplay ? "block" : "hidden"} ${sidebarDisplay ? "block" : "hiding_navbar"
            }`}
          role="dialog"
          aria-modal="true"
        >
          <div class="fixed inset-0 z-10"></div>
          <div class="fixed inset-y-0 right-0 z-10 w-2/4 h-2/3 overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div class="flex items-center justify-between">
              {/* <a href="#" class="-m-1.5 p-1.5">
                <span class="sr-only">Your Company</span>
                <img
                  class="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt=""
                />
              </a> */}
              <div></div>
              <button
                type="button"
                class="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={handleCloseSidebar}
              >
                <span class="sr-only">Close menu</span>
                <svg
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div class="mt-6 flow-root">
              <div class="-my-6 divide-y divide-gray-500/10">
                <div class="space-y-2 py-6">
                  <div class="-mx-3">
                    <Link to={"/"} onClick={scrollToTop}>
                    <button
                      type="button"
                      class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 hover:text-sky-600"
                      aria-controls="disclosure-1"
                      aria-expanded="false"
                      onClick={handleCloseSidebar}
                    >
                      Home
                    </button>
                    </Link>
                  </div>
                  <Link
                    to="/products"
                    onClick={handleCloseSidebar}
                    class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 hover:text-sky-600"
                  >
                    Product
                  </Link>
                  <Link
                    to="/resources"
                    onClick={handleCloseSidebar}
                    class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 hover:text-sky-600"
                  >
                    Resources
                  </Link>
                  <Link
                    to="/industry-support"
                    onClick={handleCloseSidebar}
                    class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 hover:text-sky-600"
                  >
                    Services
                  </Link>
                </div>
                <div class="py-6">
                  <a
                  onClick={handleCloseSidebar}
                    href={"https://org.hirestar.io/hr_portal/"}
                    target="_blank"
                    class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    <button class=" sign_in_button hover:text-sky-700">Sign in</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
