import React, { useRef } from 'react';
import "../Styles/Advantages.css"
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import jhub from "../assets/Clients_PNG/jhub.png";
import kanerika from "../assets/Clients_PNG/kanerika.png";
import startupindia from "../assets/Clients_PNG/startupindia.png";
import startups from "../assets/Clients_PNG/startups.png";
import icici from "../assets/Clients_PNG/icici-bank-logo.png";
import thub from "../assets/Clients_PNG/t-hub-logo.png";
import monitra from "../assets/Clients_PNG/monitra-logo.png";
import firstview from "../assets/Clients_PNG/first-view-logo.png";
import elogix from "../assets/Clients_PNG/elogix-logo.png";
import crimecheck from "../assets/Clients_PNG/crome-check-logo.png";
import chitmonks from "../assets/Clients_PNG/ChitMonks-logo.png";
import camfil from "../assets/Clients_PNG/camfil-logo.png";
import bluesemi from "../assets/Clients_PNG/bluesemi-logo.png";
import ariqt from "../assets/Clients_PNG/AriqT-logo.png";
import appstek from "../assets/Clients_PNG/appstek_corp_logo.png";
import JNTU from "../assets/Clients_PNG/JNTU.png"
import appzest from "../assets/Clients_PNG/Appzest.png"



export const Clients = () => {
    const images = [
        { src: kanerika, alt: 'kanerika' },
        { src: startupindia, alt: 'Startup India' },
        { src: startups, alt: 'Startups' },
        { src: icici, alt: 'icici' },
        { src: thub, alt: 'thub' },
        { src: monitra, alt: 'monitra' },
        { src: firstview, alt: 'firstview' },
        { src: elogix, alt: 'elogix' },
        { src: crimecheck, alt: 'crimecheck' },
        { src: chitmonks, alt: 'chitmonks' },
        { src: camfil, alt: 'camfil' },
        { src: bluesemi, alt: 'bluesemi' }, 
        { src: ariqt, alt: 'ariqt' },
        { src: appstek, alt: 'appstek' }, 
        { src: JNTU, alt: 'JNTU' },
        { src: appzest, alt: 'appzest' },
        // Add other images here...
    ];

    const firstSlider = useRef(null);
    const secondSlider = useRef(null);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        cssEase: 'linear', // Ensure smooth transition
        pauseOnHover: true,
        pauseOnFocus: true,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <section>
            <h1 className='advantage-heading'>Clients <span style={{ color: "black" }}>& Affiliations</span> </h1>
            <div className='w-full justify-items-center align-items-center gap-8 p-10'>
                <div className=' mt-8 '>
                    <Slider
                        {...settings}
                        asNavFor={secondSlider.current}
                        ref={firstSlider}
                    >
                        {images.map((image, index) => (
                            <div key={index} className='Clients_sliders'>
                                <img
                                    loading="lazy"
                                    className='object-cover bottom-0  '
                                    src={image.src}
                                    alt={image.alt}
                                    width="200"
                                    height="100"
                                />
                            </div>
                        ))}
                    </Slider>
                </div>
                <div className=' mt-8'>
                    <Slider
                        {...settings}
                        asNavFor={firstSlider.current}
                        ref={secondSlider}
                        rtl={true} // Enable right-to-left mode for the second slider
                    >
                        {images.map((image, index) => (
                            <div key={index} className='Clients_sliders'>
                                <img
                                    loading="lazy"
                                    className='object-cover bottom-0 '
                                    src={image.src}
                                    alt={image.alt}
                                    width="200"
                                    height="100"
                                />
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    );
};


