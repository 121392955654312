import React, { useEffect } from 'react'

// import women from "../assets/women-with-laptop.png";
// import self_verification from "../assets/ProductPage_IMG/self-verification.png"
import SvgShape from "../assets/ProductPage_IMG/graph (1).png"
import self_verification from "../assets/ProductPage_IMG/women_with_phone-transformed.jpeg"
import "../Styles/OffersProduct.css"

import { FaCheck } from "react-icons/fa6";
import { Link, useLocation } from 'react-router-dom';
import { Faq } from '../Components/Case_Study/Faq';

export const Self_Verification = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div>
                <div className='boxy w-11/12 m-auto'>
                    <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1">
                        <div className="part-1 boxy">
                            <div className="ele-center-flex2 mt-28 lg:mt-40 sm:mt-28">
                                <div className=" m-4 ">
                                    <div><h1 className='text-wrap Verification'>
                                        Reduce work load of HR by 80 % with <br /><span className='Self'>Self Verification Portal</span>
                                    </h1></div>
                                    <p className='mt-10 text-lg'>Unlock the future of seamless HR processes with <b>Hirestar.io</b>, a cutting-edge
                                        background verification solution powered by <b>blockchain technology</b>. Streamline your
                                        <b> pre-joining background verification</b> effortlessly and elevate your HR management to
                                        unprecedented heights.
                                    </p>
                                    <div className="flex  items-center flex-wrap">
                                        <div className='mt-10'><Link to={"/contact-us"} > <button className='rounded-lg text-white font-semibold border-sky-600 h-12 w-[120%] Speak-btn'>Speak to an expert</button></Link></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="part-2 boxy">
                            <div className='ele-center-flexs justify-center'>
                                <div className='p-8'>
                                    <img src={self_verification} alt="" className='w-full shadow-2xl' />
                                    {/* <img src={SvgShape} alt="" className='w-[100%] ' /> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-3/4 mt-10 text-center m-auto'>
                <p className="content_main_heading">
                    Fit and proper tests - maintain the integrity and stability of your financially regulated company.
                </p>
            </div>
            <div className='container  mt-10 m-auto'>
                <div className='w-11/12 m-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 grid-flow-row gap-8 p-10'>
                    <div className='content_card animated_content_card'>
                        {/* <h3 className='content_heading'>Virtual Offer Experience:</h3> */}
                        <div className='flex justify-start'>
                            <p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} className='justify-items-start'><FaCheck /></p>
                            <p className='content_para pl-1'>Hirestar.io is a blockchain-powered background verification company offering a
                                product known as the Self Verification Portal. This portal enables users to swiftly
                                verify their identities, requiring only a few moments for the entire process. The
                                features of the portal include multi-language support, ensuring accessibility for
                                anyone familiar with smartphone usage. With an excellent user interface and user
                                experience, the portal facilitates the verification of various ID cards, including Aadhar
                                card, PAN card, and driving license. Additionally, it supports face verification, crime
                                checks, and degree verification.
                            </p>
                        </div>
                    </div>
                    <div className='content_card animated_content_card'>
                        {/* <h3 className='content_heading'>Effortless Offer Tracking:</h3> */}
                        <div className='flex justify-start'>
                            <p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} className='justify-items-start'><FaCheck /></p>
                            <p className='content_para pl-1'>Introducing our cutting-edge solution, the Self Verification Portal, brought to you by
                                our innovative background verification company, seamlessly powered by blockchain
                                technology. Say goodbye to cumbersome verification processes and embrace the
                                future of swift, efficient self-verification.


                            </p>
                        </div>
                    </div>
                    <div className='content_card animated_content_card'>
                        {/* <h3 className='content_heading'>Proactive Issue Resolution:</h3> */}
                        <div className='flex justify-start'>
                            <p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} className='justify-items-start'><FaCheck /></p>
                            <p className='content_para pl-1'>Unlock a world of benefits with our Self Verification Portal, designed with you in
                                mind. With its intuitive user interface and seamless user experience, this portal
                                revolutionizes the way you verify your identity. No more lengthy procedures or
                                tedious paperwork - just a few moments and you're verified.


                            </p>
                        </div>
                    </div>
                    <div className='content_card animated_content_card'>
                        {/* <h3 className='content_heading'>Customized Employee Portal:
                        </h3> */}
                        <div className='flex justify-start'>
                            <p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} className='justify-items-start'><FaCheck /></p>
                            <p className='content_para pl-1'>Experience the power of multi-language support, making it accessible to anyone with
                                a smartphone. Now, language is no barrier; verification becomes effortless for users
                                worldwide. Verify crucial documents like Aadhar card, PAN card, driving license, and
                                more, effortlessly and in your preferred language

                            </p>
                        </div>
                    </div>
                    <div className='content_card animated_content_card'>
                        {/* <h3 className='content_heading'>Integrated Letter of Intent:
                        </h3> */}
                        <div className='flex justify-start'>
                            <p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} className='justify-items-start'><FaCheck /></p>
                            <p className='content_para pl-1'>But that's not all - our portal goes beyond document verification. Conduct face
                                verification and crime checks with ease. Worried about degree authenticity? We've
                                got it covered too. The Self Verification Portal is your all-in-one solution for
                                comprehensive and quick verifications.

                            </p>
                        </div>
                    </div>
                    <div className='content_card animated_content_card'>
                        {/* <h3 className='content_heading'>Huge Love from HR:
                        </h3> */}
                        <div className='flex justify-start'>
                            <p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} className='justify-items-start'><FaCheck /></p>
                            <p className='content_para pl-1'>The real game-changer? A staggering 80% reduction in HR workload. Bid farewell to
                                manual document collection and tedious verification processes. Our portal is fully
                                automatic - simply log in, create a request, and let the technology do the rest.
                            </p>
                        </div>
                    </div>
                    <div className='content_card animated_content_card'>
                        {/* <h3 className='content_heading'>Huge Love from HR:
                        </h3> */}
                        <div className='flex justify-start'>
                            <p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} className='justify-items-start'><FaCheck /></p>
                            <p className='content_para pl-1'>Don't miss out on the future of self-verification. Elevate your processes, save time,
                                and ensure accuracy with our Self Verification Portal. Take the first step towards a
                                streamlined verification experience. Your time is valuable – verify with ease, verify
                                with confidence. Click below to start your seamless journey now!
                            </p>
                        </div>
                    </div>
                </div>
                <div className="px-8  py-12">
                    <Faq />
                </div>
            </div>
        </>
    )
}
