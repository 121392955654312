import React, { useEffect, useState } from 'react'
import elipse from "../assets/Ellipse 28.png";
import '../Styles/Section_3.css'
import { FaCheck } from "react-icons/fa6";

import blockchain from "../assets/Mask group-4.png"
import streameline from "../assets/Mask group-2.png"
import integration from "../assets/Mask group-5.png"
import boosted from "../assets/Mask group-1.png"
import { Link, useLocation } from 'react-router-dom';

export default function
  Section_3() {
  const [hoveredImage, setHoveredImage] = useState(blockchain);
  const [box1Text, setBox1Text] = useState('Real-time Verification');
  const [box2Text, setBox2Text] = useState('Blockchain Security');

  const [isHover1, setIsHover1] = useState(false);
  const [isHover2, setIsHover2] = useState(false);
  const [isHover3, setIsHover3] = useState(false);
  const [isHover4, setIsHover4] = useState(false);


  const location = useLocation();

  const imageMapping = {
    'Instant Blockchain Verification': { image: blockchain, box1Text: 'Real-time Verification', box2Text: 'Blockchain Security' },
    '80% Boosted Joining Ratios :': { image: boosted, box1Text: 'Increased Acceptance', box2Text: 'Hiring Success' },
    'Streamlined Offer-to-Join Journey :': { image: streameline, box1Text: 'Seamless Onboarding', box2Text: 'Intuitive Process' },
    'Integration Without Disruption :': { image: integration, box1Text: 'Smooth Integration', box2Text: 'Workflow Harmony' }
  };

  const handleHover = (text) => {
    const { image, box1Text, box2Text } = imageMapping[text] || { image: blockchain, box1Text: 'Default Box1 Text', box2Text: 'Default Box2 Text' };
    setHoveredImage(image);
    setBox1Text(box1Text);
    setBox2Text(box2Text);
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  return (
    <section className="section">
      <div className="">
        <section className="header flex-col fill-[linear-gradient(137deg,#F1F1F1_16.86%,#DCF5FF_75.54%)] overflow-hidden relative flex min-h-[1110px] justify-center items-center px-16 py-12 max-md:px-5 ">
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/79fcb3ceb87337678fb8d34c26ef8ee449c13813330611460854bff3f3e4d388?apiKey=e943a2c06ffc4850b6049ff1030ff4ba&" className="absolute h-full w-full object-cover object-center inset-0" alt="Blue-Background" />
          <div className="relative flex w-full max-w-[1099px] flex-col items-center mt-40  max-md:max-w-full max-md:mt-10">
            <h2 className="Selfs">Why <span className="Verification">Hirestar.io?</span></h2>
            <p className="text-neutral-700 text-center text-2xl leading-7 self-stretch mt-8 max-md:max-w-full para1">
              In the ever-evolving landscape of IT talent acquisition, Hirestar.io offers
              <span className="font-bold"> a refreshing solution.</span> Traditional background verification can be slow, expensive, and prone to fraudulent activities. In response, we redefine the hiring experience with state-of-the-art solutions, ensuring expeditious, cost-effective, and highly secure vetting processes.
            </p>
            <div className="mt-14 ">
              <div className="lg:grid grid-cols-2 gap-16">
                <div className="flex flex-col items-stretch w-[90%] ml-5 max-md:w-full max-md:ml-0 lg:hidden">
                  <div className="bg-white items-start object-cover h-[100%]">
                    <img loading="lazy" src={hoveredImage} alt="hoveredImage" className="h-[100%]" width="900" height="900" />
                    <div className="absolute lg:top-[73%] lg:right-[30%] position">
                      <div className="bg-white flex-grow flex-col w-[264px] shrink-0 max-w-full h-[47px] mb-4 rounded-[40px] border-2 border-solid border-red-400 max-md:mb-10 p-2 font-semibold">
                        <p>{box1Text}</p>
                      </div>
                      <div className="bg-white flex-grow flex-col w-[264px] shrink-0 max-w-full h-[47px] rounded-[40px] border-2 border-solid border-red-400 max-md:mb-10 p-2 font-semibold">
                        <p>{box2Text}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-stretch max-md:w-full max-md:ml-0">
                  <div className="flex flex-col items-stretch my-auto py-0.5 max-md:max-w-full max-md:mt-10 lg:h-72 ">
                    {/* Details about services */}
                    <div className="flex flex-col items-stretch my-auto py-0.5 max-md:max-w-full max-md:mt-10 lg:h-72 " >
                      <div className="flex items-stretch justify-between gap-3 max-md:max-w-full max-md:flex-wrap">
                        <div className="flex w-6 shrink-0 h-6 flex-col rounded-[50%]"></div>
                        <div className="text-sky-600   leading-2 self-center grow  basis-auto my-auto max-md:max-w-full" onMouseEnter={() => { handleHover('Instant Blockchain Verification'); setIsHover1(true) }} onMouseLeave={() => setIsHover1(false)} >
                          <details className="group" open={isHover1 ? true : false}>
                            <summary className="flex font-semibold items-center cursor-pointer list-none">
                              <img loading="lazy" src={elipse} alt='elipse' width="20" height="100" /><h2 className='ml-4 mr-auto text-2xl'>Instant Blockchain Verification:</h2>
                            </summary>
                            <p className="text-neutral-600 mt-3 group-open:animate-fadeIn ">
                              Swiftly verify backgrounds, eliminating delays with our cutting-edge Blockchain technology.
                            </p>
                          </details>
                        </div>
                      </div>
                      <div className="bg-zinc-400 shrink-0 h-px mt-7 max-md:max-w-full"></div>
                      <div className="lg:flex justify-between gap-3 mt-7 items-start max-md:max-w-full max-md:flex-wrap">
                        <div className="flex w-6 shrink-0 h-6 flex-col rounded-[50%]"></div>
                        <div className="text-sky-600   leading-2 self-center grow  basis-auto my-auto max-md:max-w-full" onMouseEnter={() => { handleHover('80% Boosted Joining Ratios :'); setIsHover2(true) }} onMouseLeave={() => setIsHover2(false)}>
                          <details className="group" open={isHover2 ? true : false}>
                            <summary className="flex font-semibold text-2xl items-center cursor-pointer list-none">
                              <img loading="lazy" src={elipse} alt='elipse' width="20" height="100" /><h2 className='ml-4 mr-auto'>80% Boosted Joining Ratios:</h2>
                            </summary>
                            <p className="text-neutral-600 mt-3 group-open:animate-fadeIn fonts">
                              Achieve an 80% surge in candidate acceptance, delivering a seamless offer-to-join experience.
                            </p>
                          </details>
                        </div>
                      </div>
                      <div className="bg-zinc-400 shrink-0 h-px mt-6 max-md:max-w-full"></div>
                      <div className="flex justify-between gap-3 mt-7 items-start max-md:max-w-full max-md:flex-wrap">
                        <div className="flex w-6 shrink-0 h-6 flex-col rounded-[50%]"></div>
                        <div className="text-sky-600  leading-2 self-center grow  basis-auto my-auto max-md:max-w-full" onMouseEnter={() => { handleHover('Streamlined Offer-to-Join Journey :'); setIsHover3(true) }} onMouseLeave={() => setIsHover3(false)}>
                          <details className="group" open={isHover3 ? true : false}>
                            <summary className="flex font-semibold text-2xl items-center cursor-pointer list-none">
                              <img loading="lazy" src={elipse} alt='elipse' width="20" height="100" /><h2 className='ml-4 mr-auto'>Streamlined Offer-to-Join Journey:</h2>
                            </summary>
                            <p className="text-neutral-600 mt-3 group-open:animate-fadeIn fonts">
                              Simplify offer acceptance for candidates with a user-friendly and transparent process.
                            </p>
                          </details>
                        </div>
                      </div>
                      <div className="bg-zinc-400 shrink-0 h-px mt-6 max-md:max-w-full"></div>
                      <div className="flex justify-between gap-3 mt-7 items-start max-md:max-w-full max-md:flex-wrap mb-12">
                        <div className="flex w-6 shrink-0 h-6 flex-col rounded-[50%]"></div>
                        <div className="text-sky-600   leading-2 self-center grow  basis-auto my-auto max-md:max-w-full" onMouseEnter={() => { handleHover('Integration Without Disruption :'); setIsHover4(true) }} onMouseLeave={() => setIsHover4(false)}>
                          <details className="group" open={isHover4 ? true : false}>
                            <summary className="flex font-semibold text-2xl items-center cursor-pointer list-none">
                              <img loading="lazy" src={elipse} alt='elipse' width="20" height="100" /><h2 className='ml-4 mr-auto'>Integration Without Disruption:</h2>
                            </summary>
                            <p className="text-neutral-600 mt-2 group-open:animate-fadeIn ">
                              Seamlessly integrate our solutions without causing any disruption to your workflow.
                            </p>
                          </details>
                        </div>
                      </div>
                    </div>

                  </div>
                  <Link to="/contact-us">
                    <button aria-label="Interested? Let’s Talk" role="button" className="text-white text-2xl font-medium justify-center items-stretch shadow-md mt-32 px-10 py-4 rounded-xl max-md:max-w-full max-md:mt-10 max-md:px-5 bg-sky-600 hover:bg-sky-700 w-[85%] mb-12 custom-talk-btn">Interested? Let’s Talk</button>
                  </Link>
                </div>
                <div className="flex flex-col items-stretch w-[90%] ml-5 max-md:w-full max-md:ml-0 large-box">
                  <div className="bg-white items-start object-cover h-[100%]">
                    <img loading="lazy" src={hoveredImage} alt="hoveredImage" className="h-[100%]" />
                    <div className="absolute lg:top-[73%] lg:right-[30%] position">
                      <div className="bg-white flex-grow flex-col w-[264px] shrink-0 max-w-full h-[47px] mb-4 rounded-[40px] border-2 border-solid border-red-400 max-md:mb-10 p-2 font-semibold shadow-2xl">
                        <p className="flex items-center gap-2"><FaCheck className="text-red-500" />{box1Text}</p>
                      </div>
                      <div className="bg-white flex-grow flex-col w-[264px] shrink-0 max-w-full h-[47px] rounded-[40px] border-2 border-solid border-red-400 max-md:mb-10 p-2 font-semibold shadow-2xl">
                        <p className="flex items-center gap-2"><FaCheck className="text-red-500" />{box2Text}</p>
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );

}
