import React, { useEffect } from 'react'
import Hero_img from '../../Products/Hero_img_dummy'
import Card2 from '../../Products/Product_Cards_2'
import Card1 from '../../Products/Product_Cards_1'
import Cards3 from '../../Products/Product_Cards_3'

export const IndustriesSupport = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='FontFamily'>
            <Hero_img />
            <Card2 />
            <Card1 />
            <Cards3 />
        </div>
    )
}
