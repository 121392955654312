import React, { useEffect } from 'react'
import hr_portal from "../assets/ProductPage_IMG/hr_portal_2.png";
import "../Styles/OffersProduct.css"
import { FaCheck } from "react-icons/fa6";
import { Link, useLocation } from 'react-router-dom';
import { Faq } from '../Components/Case_Study/Faq';
export const BGV_HRportal = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div>
                <div className='boxy w-11/12 m-auto'>
                    <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1">
                        <div className="part-1 boxy">
                            <div className="ele-center-flex2 mt-20  sm:mt-28">
                                <div className=" m-4 text-lg">
                                    <div className='from-neutral-900  '><h1 className='text-wrap  Verification'>Introducing the Hirestar.io <span className='Self'> HR Portal</span> – Revolutionizing Background Verification!
                                    </h1></div>
                                    <p className='mt-10 text-lg'>Unlock the future of seamless HR processes with <b>Hirestar.io</b>, a cutting-edge
                                        background verification solution powered by <b>blockchain technology</b>. Streamline your
                                        <b> pre-joining background verification</b> effortlessly and elevate your HR management to
                                        unprecedented heights.
                                    </p>
                                    <div className="flex  items-center flex-wrap">
                                        <div className='mt-10'><Link to={"/contact-us"} > <button className='rounded-lg text-white font-semibold Speak-btn h-12 w-[120%] bg-cyan-600'>Speak to an expert</button></Link></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="part-2  ">
                            <div className='ele-center-flexs justify-center  '>
                                <div className='p-8'>
                                    <img src={hr_portal} alt="" className='w-full shadow-2xl' />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-3/4 mt-10 text-center m-auto' >
                <p className="content_main_heading">
                    Fit and proper tests - maintain the integrity and stability of your financially regulated company.
                </p>
            </div>
            <div className='container mt-10 m-auto'>
                <div className='w-11/12 m-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 grid-flow-row gap-8'>
                    <div className='content_card animated_content_card'>
                        <div className='flex justify-start items-center'><p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} ><FaCheck /></p>
                            <h3 className='content_heading'>Real-time Progress Tracking:</h3></div>
                        <p className='content_para pl-6'>Experience the power of real-time progress tracking. HRs can effortlessly process
                            and monitor background verification requests, ensuring a seamless and efficient
                            workflow.
                        </p>

                    </div>
                    <div className='content_card animated_content_card'>
                        <div className='flex justify-start items-center'><p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} ><FaCheck /></p>
                            <h3 className='content_heading'>Instant Record Queries:</h3></div>
                        <p className='content_para pl-6'>Say goodbye to tedious searches. With Hirestar.io HR Portal, query a person by name
                            and instantly check if their record is already verified, streamlining your information
                            retrieval process.

                        </p>
                    </div>
                    <div className='content_card animated_content_card'>
                        <div className='flex justify-start items-center'><p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} ><FaCheck /></p>
                            <h3 className='content_heading'>Pre-Joining Background Verification:
                            </h3></div>
                        <p className='content_para pl-6'>Break the norms of post-joining background checks. Our portal empowers you to
                            conduct background verification seamlessly during the pre-joining phase, saving
                            time and ensuring a robust hiring process.
                        </p>
                    </div>
                    <div className='content_card animated_content_card'>
                        <div className='flex justify-start items-center'><p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} ><FaCheck /></p>
                            <h3 className='content_heading'>Intuitive UI/UX for HR Managers:
                            </h3></div>
                        <p className='content_para pl-6'>Navigate with ease through a user-friendly interface designed specifically for HR
                            managers and admins. Enjoy a seamless and visually appealing experience that
                            enhances productivity.

                        </p>
                    </div>
                    <div className='content_card animated_content_card'>
                        <div className='flex justify-start items-center'><p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} ><FaCheck /></p>
                            <h3 className='content_heading'>Integrated Letter of Intent:
                            </h3></div>
                        <p className='content_para pl-6'>Forge meaningful connections from the start. The portal introduces a Letter of Intent
                            feature, enabling you to create first interaction letters for employees before they join.
                            Seamlessly integrated with our background verification, this feature kickstarts the
                            comprehensive onboarding process.
                        </p>
                    </div>
                    <div className='content_card animated_content_card'>
                        <div className='flex justify-start items-center'><p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} ><FaCheck /></p>
                            <h3 className='content_heading'>Top-notch Security:
                            </h3></div>
                        <p className='content_para pl-6'>Rest easy with the best-in-class security provided by blockchain technology. Your
                            data is safeguarded, ensuring confidentiality and integrity throughout the verification
                            process.</p>
                    </div>
                    <div className='content_card animated_content_card'>
                        <div className='flex justify-start items-center'><p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} ><FaCheck /></p>
                            <h3 className='content_heading'>Downloadable Reports in PDF Format:

                            </h3></div>
                        <p className='content_para pl-6'>Access comprehensive reports effortlessly. Download verified reports in PDF format,
                            allowing for easy storage, sharing, and documentation.
                        </p>
                    </div>
                    <div className='content_card animated_content_card'>
                        <div className='flex justify-start items-center'><p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} ><FaCheck /></p>
                            <h3 className='content_heading'>Analytical Insights:

                            </h3></div>
                        <p className='content_para pl-6'>Gain a deeper understanding of your verification processes with detailed graphs and
                            charts. Monitor your requests on a monthly, quarterly, and yearly basis, enabling
                            data-driven decision-making.</p>
                    </div>
                    <div className='content_card animated_content_card'>
                        <div className='flex justify-start items-center'><p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} ><FaCheck /></p>
                            <h3 className='content_heading'>Supercharge Your HR Toolkit – Act Now!
                            </h3></div>
                        <p className='content_para pl-6'>Revolutionize your background verification processes with Hirestar.io HR Portal.
                            Unlock unprecedented efficiency, security, and analytical insights. Don't just manage,
                            excel! Upgrade to the future of HR processes. Act now and elevate your HR game
                            with Hirestar.io HR Portal. Your path to HR excellence starts here!</p>
                    </div>
                    <div className='content_card animated_content_card'>
                        {/* <h3 className='content_heading'>Virtual Offer Experience:</h3> */}
                        <div className='flex justify-start'>
                            <p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} className='justify-items-start'><FaCheck /></p>
                            <p className='content_para pl-1'>Immerse yourself in the benefits of Hirestar.io's HR Portal, meticulously designed for
                                HR professionals seeking efficiency and security. Experience the power to process all
                                background verification requests with unparalleled ease. No more tedious
                                post-joining procedures; Hirestar.io empowers you to conduct background
                                verification during the pre-joining phase, saving time and ensuring a robust hiring
                                process.
                            </p>
                        </div>
                    </div>
                    <div className='content_card animated_content_card'>
                        {/* <h3 className='content_heading'>Effortless Offer Tracking:</h3> */}
                        <div className='flex justify-start'>
                            <p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} className='justify-items-start'><FaCheck /></p>
                            <p className='content_para pl-1'>Our intuitive user interface and user experience redefine the way Human Resource
                                managers and admins interact with background verification. Navigate effortlessly
                                through the system, checking progress in real-time and querying individuals by name
                                to verify their records promptly. Say goodbye to uncertainty; Hirestar.io keeps you
                                informed every step of the way.

                            </p>
                        </div>
                    </div>
                    <div className='content_card animated_content_card'>
                        {/* <h3 className='content_heading'>Proactive Issue Resolution:</h3> */}
                        <div className='flex justify-start'>
                            <p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} className='justify-items-start'><FaCheck /></p>
                            <p className='content_para pl-1'>What sets us apart? Unrivaled security backed by blockchain technology ensures
                                your data is safeguarded at every level. Feel confident in the integrity of your
                                background verification process, knowing it meets the highest standards in the
                                industry.

                            </p>
                        </div>
                    </div>
                    <div className='content_card animated_content_card'>
                        {/* <h3 className='content_heading'>Customized Employee Portal:
                        </h3> */}
                        <div className='flex justify-start'>
                            <p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} className='justify-items-start'><FaCheck /></p>
                            <p className='content_para pl-1'>Download verification reports effortlessly in PDF format, providing a tangible record
                                of each candidate's background check. But it doesn't stop there - monitor your
                                requests with insightful graphs and charts on a monthly, quarterly, and yearly basis.
                                Stay ahead of the curve with comprehensive analytics at your fingertips.

                            </p>
                        </div>
                    </div>
                    <div className='content_card animated_content_card'>
                        {/* <h3 className='content_heading'>Integrated Letter of Intent:
                        </h3> */}
                        <div className='flex justify-start'>
                            <p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} className='justify-items-start'><FaCheck /></p>
                            <p className='content_para pl-1'>Ready to elevate your HR game? Choose Hirestar.io HR Portal for a seamless,
                                secure, and efficient background verification experience. Revolutionize your hiring
                                process today!

                            </p>
                        </div>
                    </div>
                    <div className='content_card animated_content_card'>
                        {/* <h3 className='content_heading'>Huge Love from HR:
                        </h3> */}
                        <div className='flex justify-start'>
                            <p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} className='justify-items-start'><FaCheck /></p>
                            <p className='content_para pl-1'>Don't miss out – Empower your HR team with <b>Hirestar.io</b> now!</p>
                        </div>
                    </div>
                </div>
                <div className="px-8  py-12">
                    <Faq />
                </div>
            </div>
        </>
    )
}
