import React from 'react'
/* import image from "../assets/image52.png" */
import card1 from "../assets/card1.png"
import { IoFingerPrintSharp } from "react-icons/io5";
import { FaGraduationCap } from "react-icons/fa";
import { IoIosPerson } from "react-icons/io";
import { FaHeartbeat } from "react-icons/fa";
import { BsPiggyBankFill } from "react-icons/bs";
import { IoShieldCheckmark } from "react-icons/io5";


/* cards imports */
import { FaRegIdCard } from "react-icons/fa6";
import { FaRegAddressCard } from "react-icons/fa";
import { FaIdCard } from "react-icons/fa";
import { LuScanFace } from "react-icons/lu";

import { FaPassport } from "react-icons/fa6";
import { FaCarSide } from "react-icons/fa";
import { IoHome } from "react-icons/io5";
import { IoSpeedometer } from "react-icons/io5";
import { Link } from 'react-router-dom';

export default function
  Card2() {
  return (

    <div>
      <div className='boxy '>

        <div className="grid lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 items-baseline">


          <div className="part-1 boxy">


            <div className="ele-center-flex2 mt-[8rem] justify-center 	">
              <div className=" m-4  w-[80%] font-semibold text-lg">
                <div className='from-neutral-900 text-5xl font-bold '><h1 className='text-wrap '>Services</h1></div>
                <div className='mt-10'>
                  <div className='grid grid-cols-3  gap-3'>
                    <div className='p-2 flex flex-col items-center'>
                      <p className='p-1 service_icon'><IoShieldCheckmark /></p>
                      <p className='text-center'>Crime Check</p>
                    </div>
                    <div className='p-2 flex flex-col  items-center'>
                      <p className='p-1 service_icon'><FaHeartbeat /></p>
                      <p className='text-center'>Drug Test</p>
                    </div>
                    <div className='p-2 flex flex-col items-center'>
                      <p className='p-1 service_icon'><FaCarSide /></p>
                      <p className='text-center'>Driving Licence</p>
                    </div>
                    <div className='p-2 flex flex-col items-center'>
                      <p className='p-1 service_icon'><IoHome /></p>
                      <p className='text-center'>Address Check</p>
                    </div>
                    <div className='p-2 flex flex-col items-center'>
                      <p className='p-1 service_icon'><LuScanFace /></p>
                      <p className='text-center'>Face Verification</p>
                    </div>
                    <div className='p-2 flex flex-col items-center'>
                      <p className='p-1 service_icon'><FaRegAddressCard /></p>
                      <p className='text-center'>PAN Verification</p>
                    </div>

                    {/*   <div className='p-2 flex flex-col justify-center items-center'>
                      <p className='p-1 service_icon'><IoFingerPrintSharp /></p>
                      <p className='text-center'>Identity Verification</p>
                    </div> */}
                    <div className='p-2 flex flex-col justify-center items-center'>
                      <p className='p-1 service_icon'><FaGraduationCap /></p>
                      <p className='text-center'>Degree Verification</p>
                    </div>
                    <div className='p-2 flex flex-col justify-center items-center'>
                      <p className='p-1 service_icon'><IoIosPerson /></p>
                      <p className='text-center'>Employment Verification</p>
                    </div>

                    <div className='p-2 flex flex-col justify-center items-center'>
                      <p className='p-1 service_icon'><BsPiggyBankFill /></p>
                      <p className='text-center'>Financial Integrity Check</p>
                    </div>


                    <div className='p-2 flex flex-col items-center'>
                      <p className='p-1 service_icon'><FaRegIdCard /></p>
                      <p className='text-center'>Aadhar Verification</p>
                    </div>





                    <div className='p-2 flex flex-col items-center'>
                      <p className='p-1 service_icon'><FaPassport /></p>
                      <p className='text-center'>Passport Verification</p>
                    </div>



                    <div className='p-2 flex flex-col items-center'>
                      <p className='p-1 service_icon'><IoSpeedometer /></p>
                      <p className='text-center'>CIBIL Score Check</p>
                    </div>


                  </div>
                </div>
                {/*  <div className='mt-10'><button className='text-cyan-600'>How it Works <span className='text-red-500 font-bold text-lg'>&gt;</span> </button></div >*/}

              </div>

            </div>
          </div>
          <div className="part-2 boxy">
            <div className='ele-center-flexs justify-end	 '>
              <div className='p-8 mt-4'>
                <div className=" m-4  lg:w-[70%]  text-lg">
                  <div className='Verification'>
                    <h1 className='text-wrap'>
                      Every Background Check
                     
                    </h1>
                  </div>
                  <div className='mt-10 text-xl '>No matter which verifications you require, regardless of your candidate's location in India, we've got you covered. Whether it's criminal background checks, credit history reviews, employment eligibility confirmations, or reference verifications, streamline your hiring process with hirestar.io.</div>
                  <div className="flex  items-center flex-wrap">
                    <Link to="/contact-us">  <div className='mt-10'><button className='rounded-lg text-white font-semibold Speak-btn h-12 w-[120%] Speak-btn'>Speak to an expert</button></div></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>












  )
}
