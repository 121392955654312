import React, { useEffect, useState } from 'react';
import "../Styles/Testimonials.css";
import stars from "../assets/Testimonials_PNG/5star.png"
import bewra from "../assets/Testimonials_PNG/vijay bawra.png";
import garud from "../assets/Testimonials_PNG/samidha-garud.jpg";
import kumari from "../assets/Testimonials_PNG/vijaya-kumari.jpg";
import polaris from "../assets/Testimonials_PNG/polaris.png"
import Slider from "react-slick";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { Link } from 'react-router-dom';

const images = [
  {
    image: stars,
    person: garud,
    description: "The HireStar offer experience is a hit with my HR team, We saved a countless hours with the predicition tool and we are already observing improved joins ratios.",
    name: "Samidha Garud",
    role: "Co-founder Kanerika Inc"
  },
  {
    image: stars,
    person: bewra,
    description: "HireStar is a boon for recruiters. We can prevent fake profiles and the recruitment cycle time can be reduced from weeks to a couple of days",
    name: "Vijay Bawra",
    role: "Regional Head Nasscom 10000 startups program Andhra Pradesh & Telangana."
  },
  {
    image: stars,
    person: kumari,
    description: "Congratulations for the wonderful platform HireStar. I am sure this would open a new door for recruitment and verification. Also as it is on blockchain only the additional qualification/skills obtained need be verified from time to time.",
    name: "Vijay Kumari",
    role: "Director JHUB, JNTU"
  },
  {
    image: stars,
    person: polaris,
    description: "HireStar.io has been a game-changer for our recruitment process. By leveraging blockchain technology, they have not only ensured the authenticity of candidate profiles but also reduced our HR workload remarkably.",
    name: "Dheeraj Reddy",
    role: "Managing Director @Polaris Infratech Private Limited"
  },
];

export const Testimonials = () => {
  const [activeIndex, setActiveIndex] = useState(0);


  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  };

  const [imageIndex, setImageIndex] = useState(0);

  const desktopSettings = {
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
  };

  const mobileSettings = {
    infinite: true,
    lazyLoad: true,
    speed: 300,
    dots: true,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: 0,
    /* nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />, */
    beforeChange: (current, next) => setImageIndex(next),
  };

  useEffect(() => {
    // Add event listener to update the window width on resize
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Determine the current screen size
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Choose settings based on screen size
  const currentSettings = windowWidth >= 768 ? desktopSettings : mobileSettings;


  return (
    <>



      <div>
        <header class="header flex-col fill-[linear-gradient(137deg,#F1F1F1_16.86%,#DCF5FF_75.54%)] overflow-hidden relative flex min-h-[1110px] justify-center items-center px-16 py-12 max-md:px-5 ">
          <p className='service-heading flex justify-center items-center mb-16 gap-4 z-10'>Client <span style={{ color: "#0D6EB5" }} class="Verification"> Testimonials</span> </p>

          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/79fcb3ceb87337678fb8d34c26ef8ee449c13813330611460854bff3f3e4d388?apiKey=e943a2c06ffc4850b6049ff1030ff4ba&" className="absolute h-full w-full object-cover object-center inset-0" alt='Blue-Background'/>
          <div class="relative flex w-full max-w-[1099px] flex-col items-center  max-md:max-w-full max-md:mt-10">
            <div class=" text-center  self-stretch mt-8 max-md:max-w-full ">
              <div className='carousel_container'>
                <Slider {...currentSettings} className='slider_container '>
                  {images.map((img, idx) => (
                    <div className={idx === imageIndex ? "activeSlide card-props shadow-lg mb-2" : "slide card-props"}>
                      <div className='image_div mb-4'>
                        <img src={img.image} alt="Image 1" width="100" height="100"/>
                      </div>
                      <div className='child_slider mb-4'>
                        <p className='slider_description '>{img.description}</p>
                      </div>
                      <div className='image_person mb-4'>
                        <img className='person_img' src={img.person} alt="Image 2" />
                      </div>
                      <div className='child_slider'>
                        <h2 style={{ color: "#0D6EB5", fontSize: "22px", fontFamily: "Raleway", fontWeight: "700", textAlign: "center" }}>{img.name}</h2>
                        <span style={{ fontSize: "10px", fontWeight: "bold" }}>{img.role}</span>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>

          </div>
        </header>
      </div>
      <div className='container mx-auto py-24 px-5' >
        <div className='rounded-[20px] lg:w-[50%] sm:full m-auto text-center sm:p-8 md:p-8 baground_card shadow-md'>
          <p className='testimonial_caption'>See the Power of Our Platform</p>
          <h1 className='testimonial_heading'>Connect, Scale, and Thrive.</h1>
          <Link to="/contact-us">   <button className='tentimonial_button'>Request Demo</button></Link>
        </div>
      </div>
    </>

  )
};

