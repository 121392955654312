import React from 'react'
import "../Styles/PageNotFound.css"
import { Link } from 'react-router-dom'

export const PageNotFound = () => {
    return (
        <section class="page_404" >
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="col-sm-12 col-sm-offset-1 text-center">
                            <div class="d-flex four_zero_four_bg justify-content-center">
                                <h1 class="text-center ">404</h1>
                            </div>
                            <div class="contant_box_404">
                                <h3 class="card-text">Looks like you're lost</h3>
                                <h5 class="card-text">the page you are looking for is not available!</h5>
                                <Link to={"/"}><div className='rounded-lg md:col-span-2 px-4 mb-4 w-1/5 m-auto'>  <button className='m-auto h-auto rounded-lg md:col-span-2 text-white font-semibold border-sky-600 Speak-btn pr-4  mt-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500'>Homepage</button></div></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
