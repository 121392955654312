import React, { useState } from "react";
// import { MdArrowForwardIos } from "react-icons/md";
import "./Navbar_Product_Dropdown.css";
import arrow from "../../assets/servicesImages/arrow.png";
import { ServicesComponents } from "./ServicesComponents";

export const NavbarProductDropdown = ({ setIsProductHovered }) => {
  // const [ishovered, setIshovered] = useState(false);
  const servicesArray = [
    {
      service_name: "BGV HR Portal",
      description:
        "Hirestar.io streamlines talent acquisition by providing insights before engaging with candidates.",
      path: "/bgv-hr-portal"
    },
    {
      service_name: "Offer Experience",
      description:
        "Simplify hiring with our transparent log for swift decision-making from offer to acceptance.",
      path: "/offer-experience"
    },
    {
      service_name: "Self Verification",
      description:
        "Secure document uploads and real-time tracking putting individuals in control, ensuring a seamless verification journey.",
      path: "/self-verification"
    },
    {
      service_name: "GR+ Portal",
      description:
        "Effortlessly register complaints against candidates, ensuring transparency and accountability in your recruitment endeavors.",
      path: "/gr-plus-portal"
    },
  ];

  const popularChecksArray = [
    {
      nameOfService: "CIFAS",
      image:
        "https://assets-global.website-files.com/63fef929dc32cb28d93d6c87/64c9f882899966dd9475c466_type%3Dfinanc-reg.png",
    },
    {
      nameOfService: "MOM education verification",
      image:
        "https://assets-global.website-files.com/63fef929dc32cb28d93d6c87/64c9f762cde1577e5e267880_type%3Dfinanc-reg.png",
    },
    {
      nameOfService: "Fit and Proper",
      image:
        "https://assets-global.website-files.com/63fef929dc32cb28d93d6c87/64801bf61e635fc2624ab274_type%3DF%26P.png",
    },
    {
      nameOfService: "Identity",
      image:
        "https://assets-global.website-files.com/63fef929dc32cb28d93d6c87/641c5803950eeae146649d6b_checks-6.png",
    },
    {
      nameOfService: "Social media",
      image:
        "https://assets-global.website-files.com/63fef929dc32cb28d93d6c87/6426f5bdd867ab38d1646555_social%20media.svg",
    },
    {
      nameOfService: "Right to work",
      image:
        "https://assets-global.website-files.com/63fef929dc32cb28d93d6c87/641c47f296ff76747ed9d016_checks-14.png",
    },
    {
      nameOfService: "Reference",
      image:
        "https://assets-global.website-files.com/63fef929dc32cb28d93d6c87/641c47f296ff76747ed9d016_checks-14.png",
    },
    {
      nameOfService: "Employement history",
      image:
        "https://assets-global.website-files.com/63fef929dc32cb28d93d6c87/641c4daf1f41df755539c9c3_checks-7.png",
    },
    {
      nameOfService: "Professional qualification",
      image:
        "https://assets-global.website-files.com/63fef929dc32cb28d93d6c87/6426f5dfe1c26f72b43d629d_professional%20qualification.svg",
    },
    {
      nameOfService: "All checks",
      image:
        "https://assets-global.website-files.com/63fef929dc32cb28d93d6c87/641c64bcf505af8927760c9f_checks-3.png",
    },
  ];

  return (
    <div
      className={`container FontFamily absolute h-[350px] w-[700px] -left-[200px] rounded-lg top bg-white p-4 flex gap-2 pt-12 shadow-md z-10`}
      onMouseEnter={() => setIsProductHovered(true)}
      onMouseLeave={() => setIsProductHovered(false)}
    // style={{
    //   width: "658px",
    //   right: "250px",
    //   top: "55px",
    //   backgroundColor: "white",
    // }}
    >
      <div className="grid grid-cols-2 gap-4 px-4 w-full ">
        {servicesArray?.map((ele) => {
          return (
            <ServicesComponents service_name={ele.service_name} image={arrow} description={ele.description} path={ele.path} />
          );
        })}
      </div>
      {/* <div className="grid grid-cols-2 w-1/2">
        <div className="">
          <p className="popular_checks_text">Popular checks</p>
        </div>
        <div className=""></div>
        {popularChecksArray?.map((ele) => {
          return (
            <div className=" py-2 cursor-pointer">
              <div className="popular_checks_container" key={ele.nameOfService}>
                <img src={ele.image} alt="" className="h-6" />
                <p className="text-xl font-medium text-sky-600">
                  {ele.nameOfService}
                </p>
                <span>{ele.image}</span>
              </div>
              <p></p>
            </div>
          );
        })}
      </div> */}
    </div>
  );
};
