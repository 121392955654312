import React from 'react'
import women_with_laptop from "../assets/women-with-laptop.png"
import semi_circle from "../assets/Ellipse 23.svg"
import identity_image from '../assets/identy_verification.png'
import health_care from "../assets/HealthCare.png";
import drug_test from "../assets/drug_test.png";
import crime_check from "../assets/Crime.png";

export default function Hero_img() {
  return (
    <div>

      <div className='absolute right-0 bottom-0'>

        <img src={semi_circle} alt="Circle-icon" width="600" height="500" />
      </div>
      <div className='absolute right-0 bottom-0'>

        <img className='lg:relative right-[14%]' src={women_with_laptop} alt="women_with_laptop" width="600" height="600" />
        <div class="image-container ">
          <img src={identity_image} alt="identity_image" class=" progressive-image animation" width="100" height="100" />
          <img src={drug_test} alt="health_care" class="progressive-image2 animation2" width="100" height="100" />
          <img src={crime_check} alt="crime_check" class="progressive-image3 animation3" width="100" height="100" />
        </div>
      </div>
    </div>
  )
}
