import React from "react";
import "./Resource_card.css";

export const Resource_card = ({image,title,author,readingTime,fontTitle,fontAuthor}) => {
  return (
    <div className="container p-4 cursor-pointer">
      <div className="img_wrapper">
        <img
          src={image}
          alt=""
          className=""
        />
      </div>
      <div className="blog_title">
        <p className={`${fontTitle ? `lg:${fontTitle}` : "lg:text-2xl"} md:text-xl sm:text-lg font-bold`}>
          {title}
        </p>
      </div>
      <div className="blog_footer">
        <p className={`${fontAuthor ? `lg:${fontAuthor}` : "lg:text-xl"} md:text-lg sm:text-md font-bold text-gray-400`}>
          {author}<span> {readingTime}</span>
        </p>
      </div>
    </div>
  );
};
