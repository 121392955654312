import React from "react";
import { Link } from "react-router-dom";

export const Clients_case_study_card = ({clients_case_study_array}) => {

  function trimAndEllipsis(inputString) {
    // Check if the length of the string is greater than 50 characters
    if (inputString.length > 70) {
        // Trim the string to 50 characters
        const trimmedString = inputString.substring(0, 50);

        // Add three dots at the end
        const trimmedAndEllipsis = trimmedString + '...';

        return trimmedAndEllipsis;
    }

    // If the length of the string is 50 characters or less, return the original string
    return inputString;
}


  return (
    <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-12 justify-items-center FontFamily">
      {clients_case_study_array.map((ele) => (
        <Link to={`/case-studies/${ele.case_study_name.replace(/ /g, "+")}`} key={ele.case_study_name}>
        <div class="relative flex flex-col shadow-md rounded-xl overflow-hidden hover:shadow-lg hover:-translate-y-1 transition-all duration-300 max-w-sm">
          <div class="h-auto overflow-hidden">
            <div class="h-44 overflow-hidden relative">
              <img src={ele.image} alt="" className="h-[100%] w-full object-cover" />
            </div>
          </div>
          <div class="bg-white py-4 px-3">
            <h3 class="text-2xl mb-2 font-medium">{trimAndEllipsis(ele.case_study_name)}</h3>
          </div>
        </div>
        </Link>
      ))}
    </div>
  );
};
