import React, { useEffect } from 'react'
import logo from '../assets/hirestar_logo.png'
import { Link } from 'react-router-dom';
import { FaTwitter } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { FaLinkedin } from "react-icons/fa";
export default function Footer() {
    let date = new Date().getFullYear();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    return (
        <div className='mt-16'>
            <header class="bg-[linear-gradient(137deg,#F1F1F1_16.86%,#DCF5FF_75.54%)] px-16 lg:py-2 m-bottom max-md:px-5">
                <div class="gap-12 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div class="flex flex-col items-stretch w-[36%] max-md:w-full max-md:ml-0">
                        <div class=" mt-2 items-start max-md:mt-10">
                            <Link to="/">  <div onClick={scrollToTop} class="border w-[40%] lg:w-[60%] sm:w-[40%] bg-white self-stretch flex flex-col justify-center pl-12 pr-12 py-2.5 rounded-[50px] border-solid border-stone-300 items-start max-md:px-5 mt-2">
                                <img loading="lazy" src={logo} alt="logo" width="100" height="100" />
                            </div></Link>
                            <div class="text-neutral-700 text-lg leading-8 self-stretch mt-9">Offer Experience and Employee Background Verification powered by blockchain</div>
                            {/* <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/88c73b645eea770ee3abecbe66c87825440ee384247406e713ffaa090d7d65f6?apiKey=e943a2c06ffc4850b6049ff1030ff4ba&" class="aspect-[3.46] object-contain object-center w-[97px] overflow-hidden max-w-full mt-5" /> */}
                            <div class="flex gap-3 social-links mt-3">
                                <a href="https://twitter.com/Hirestario?t=YOq_b92bZL8gZxDuS21hRQ&s=09" target='_blank'> <p className='text-cyan-600 hover:text-cyan-700 cursor-pointer text-xl' aria-label="Twitte"><FaTwitter /></p></a>
                                <a href="https://www.instagram.com/hirestar.io/" target='_blank'>  <p className='text-cyan-600 hover:text-cyan-700 cursor-pointer text-xl' aria-label="Instagram"> <RiInstagramFill /></p></a>
                                <a href="https://in.linkedin.com/showcase/hirestar.io/" target='_blank'> <p className='text-cyan-600 hover:text-cyan-700 cursor-pointer text-xl' aria-label="Linkdin"> <FaLinkedin /></p></a>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col items-stretch w-[16%] ml-5 max-md:w-full max-md:ml-0">
                        <div class="flex flex-col items-stretch my-auto max-md:mt-10 mt-6">
                            <div class="text-sky-600 text-2xl font-bold leading-7 whitespace-nowrap">Useful Links</div>
                            <div class="text-neutral-700 text-lg leading-8 mt-11 max-md:mt-10">
                                <div className="UsefulLinks">
                                    <div className=' hover:text-sky-700' onClick={scrollToTop}><Link to={"/"}>Why hirestar.io?</Link></div>
                                    <div className=' hover:text-sky-700'><Link to={"/products"}>Products</Link></div>
                                    <div className=' hover:text-sky-700'><Link to={"/industry-support"}>Services</Link></div>
                                    <div className=' hover:text-sky-700'><Link to={"/resources"}>Resources</Link></div>
                                    <div className=' hover:text-sky-700'><a href="https://hirestar.io/vendor/termspolicy.html" target='_blank'>Privacy Policy</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col items-stretch w-[16%] ml-5 max-md:w-full max-md:ml-0">
                        <div class="flex flex-col items-stretch mt-6 max-md:mt-10">
                            <div class="text-sky-600 text-2xl font-bold leading-7">Services</div>
                            <div class="text-neutral-700 text-lg leading-8 mt-11 max-md:mt-10">
                                <div className='hover:text-sky-700'><Link to={"/self-verification"}>Self Verification</Link></div>
                                <div className='hover:text-sky-700' onClick={scrollToTop}><Link to={"/"}>Blockchain Search</Link></div>
                                <div className='hover:text-sky-700'><Link to={"/offer-experience"}>Offer Experience</Link></div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col items-stretch w-[32%] ml-5 max-md:w-full max-md:ml-0">
                        <div class="flex flex-col my-auto max-md:mt-10 mt-6">
                            <Link to="/contact-us"> <div class="text-sky-600 text-2xl font-bold leading-8 self-stretch ">Contact Us</div></Link>
                            <div class="flex gap-5 mt-11 self-start items-start">
                                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/47b432c7ab1a1cf873b6a85c677a8cc513d20cda80114e88f92c7f17440e24f1?apiKey=e943a2c06ffc4850b6049ff1030ff4ba&" class="aspect-[0.94] object-contain object-center w-[15px] fill-neutral-700 overflow-hidden shrink-0 max-w-full" alt='Phone-Icon' />
                                <div class="text-neutral-700 text-lg leading-5 w-full ">040-40198080</div>
                            </div>
                            <div class="self-stretch flex justify-between gap-4 mt-4 items-start">
                                <a href="mailto:admin@hirestar.io" className='flex justify-between gap-4'>
                                    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/df1ca3a2b2d96d3fe9ad1eef5c418d683b11b8cab85e2ce3f95623c0c2486d61?apiKey=e943a2c06ffc4850b6049ff1030ff4ba&" class="aspect-[1.29] object-contain object-center w-[18px] fill-neutral-700 overflow-hidden shrink-0 max-w-full" alt='Email-Icon' />
                                    <div class="text-neutral-700 text-lg leading-5 grow whitespace-nowrap">admin@hirestar.io</div>
                                </a>
                            </div>

                            <div class="self-stretch flex justify-between gap-5 mt-4 items-start">
                                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/71abfe753bfd821155594e5b6e2f5092f35c722c653dba3c79551025fe53d80c?apiKey=e943a2c06ffc4850b6049ff1030ff4ba&" class="aspect-[0.88] object-contain object-center w-[15px] fill-neutral-700 overflow-hidden shrink-0 max-w-full" alt='Location-Icon' />
                                <div class="text-neutral-700 text-lg leading-6 grow shrink basis-auto">DappLogix Software Pvt Ltd, J20, Inorbit Mall Rd, Vittal Rao Nagar, Madhapur, Hyderabad, Telangana 500081, India</div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className='text-center mt-8'>
                  ©  Copyright  <b><a href="https://org.hirestar.io/">Hirestar.io</a></b> ® Registered trademark of <b>Dapplogix Software Pvt Ltd</b> - All Rights reserved {date}
                </footer>
            </header>
        </div>
    )
}
