import React, { useEffect } from 'react'
import grplus from "../assets/ProductPage_IMG/gr-pluss.jpeg";
import SvgShape from "../assets/ProductPage_IMG/graph (1).png"
import "../Styles/OffersProduct.css"

import { FaCheck } from "react-icons/fa6";
import { Link, useLocation } from 'react-router-dom';
import { Faq } from '../Components/Case_Study/Faq';
export const GR_portal = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div >
            <div className='boxy w-11/12 m-auto'>
                <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1">
                    <div className="part-1 boxy">
                        <div className="ele-center-flex2 mt-28 lg:mt-40 sm:mt-28">
                            <div className=" m-4 ">
                                <div><h1 className='text-wrap Verification'>Welcome to the future of <span className='Self'>background verification !</span>
                                </h1></div>
                                <p className='mt-10 text-lg'>Unlocking Talent Acquisition Efficiency with Hirestar.io's GR+ Feature
                                    In the competitive landscape of talent acquisition, organizations seek streamlined processes to ensure they onboard the best-fit candidates efficiently. Hirestar.io emerges as a leading player in this arena, offering comprehensive background verification services. At the heart of its offerings lies the groundbreaking GR+ feature, a free grievance reporting tool designed to empower HR professionals in navigating the complexities of talent acquisition seamlessly.
                                </p>
                                <div className="flex  items-center flex-wrap">
                                    <div className='mt-10'><Link to={"/contact-us"} > <button className='rounded-lg text-white font-semibold Speak-btn h-12 w-[120%] Speak-btn'>Speak to an expert</button></Link></div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="part-2">
                        <div className='ele-center-flexs justify-center'>
                            <div className='p-8'>
                                <img src={grplus} alt="" className='w-full shadow-2xl' />
                                {/* <img src={SvgShape} alt="" className='w-[100%] ' /> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-3/4 mt-10 text-center m-auto'>
                <p className="content_main_heading">
                    Fit and proper tests - maintain the integrity and stability of your financially regulated company.
                </p>
            </div>
            <div className='container mt-10 m-auto'>
                <div className='w-11/12 m-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 grid-flow-row gap-8'>
                    <div className='content_card animated_content_card'>
                        <div className='flex justify-start items-center'><p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} ><FaCheck /></p>
                            <h3 className='content_heading'>Real-time Progress Tracking:</h3></div>
                        <p className='content_para pl-6'>Experience the power of real-time progress tracking. HRs can effortlessly process
                            and monitor background verification requests, ensuring a seamless and efficient
                            workflow.
                        </p>

                    </div>
                    <div className='content_card animated_content_card'>
                        <div className='flex justify-start items-center'><p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} ><FaCheck /></p>
                            <h3 className='content_heading'>Understanding GR+: A Game-Changing Feature</h3></div>
                        <p className='content_para pl-6'>GR+ represents a paradigm shift in talent acquisition methodologies. This innovative feature serves as a catalyst for enhancing HR operations by providing a centralized platform for reporting and managing various grievances related to talent acquisition. With GR+, HR professionals gain the ability to address critical issues such as moonlighting, offer shopping, absconders, and terminations on ethical and disciplinary grounds with unparalleled ease.
                        </p>
                    </div>
                    <div className='content_card animated_content_card'>
                        <div className='flex justify-start items-center'><p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} ><FaCheck /></p>
                            <h3 className='content_heading'>How GR+ Works:
                            </h3></div>
                        <p className='content_para pl-6'>GR+ simplifies the grievance reporting process, allowing HR teams to submit and track grievances effortlessly. Upon identification of potential red flags in candidate profiles, HR professionals can leverage GR+ to initiate investigations promptly, ensuring compliance with organizational standards and regulatory requirements.
                        </p>
                    </div>
                    <div className='content_card animated_content_card'>
                        <div className='flex justify-start items-center'><p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} ><FaCheck /></p>
                            <h3 className='content_heading'>Intuitive UI/UX for HR Managers:
                            </h3></div>
                        <p className='content_para pl-6'>Navigate with ease through a user-friendly interface designed specifically for HR
                            managers and admins. Enjoy a seamless and visually appealing experience that
                            enhances productivity.

                        </p>
                    </div>
                    <div className='content_card animated_content_card'>
                        <div className='flex justify-start items-center'><p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} ><FaCheck /></p>
                            <h3 className='content_heading'>Enhancing Candidate Screening Efficiency:
                            </h3></div>
                        <p className='content_para pl-6'>One of the key functionalities of GR+ is its capability to facilitate candidate screening based on flagged issues. By enabling HR to search candidates for specific grievances, Hirestar.io empowers organizations to make informed hiring decisions while mitigating potential risks associated with unfit candidates.
                        </p>
                    </div>
                    <div className='content_card animated_content_card'>
                        <div className='flex justify-start items-center'><p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} ><FaCheck /></p>
                            <h3 className='content_heading'>Leveraging GR+ for Effective Talent Management:
                            </h3></div>
                        <p className='content_para pl-6'>Beyond its role in grievance reporting and candidate screening, GR+ offers a myriad of benefits for talent management within organizations.</p>
                    </div>
                    <div className='content_card animated_content_card'>
                        <div className='flex justify-start items-center'><p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} ><FaCheck /></p>
                            <h3 className='content_heading'>Promoting Transparency and Accountability:

                            </h3></div>
                        <p className='content_para pl-6'>GR+ fosters a culture of transparency and accountability within the organization by providing a structured framework for addressing grievances. By encouraging open communication channels, Hirestar.io facilitates the resolution of conflicts and promotes a positive work environment conducive to employee growth and development.
                        </p>
                    </div>
                    <div className='content_card animated_content_card'>
                        <div className='flex justify-start items-center'><p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} ><FaCheck /></p>
                            <h3 className='content_heading'>Mitigating Legal and Reputational Risks:

                            </h3></div>
                        <p className='content_para pl-6'>In today's litigious environment, organizations face heightened scrutiny regarding their hiring practices. GR+ serves as a safeguard against legal and reputational risks by ensuring thorough due diligence in candidate vetting. By proactively addressing issues such as ethical violations and disciplinary actions, organizations can safeguard their brand reputation and mitigate potential legal liabilities.</p>
                    </div>
                    <div className='content_card animated_content_card'>
                        <div className='flex justify-start items-center'><p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} ><FaCheck /></p>
                            <h3 className='content_heading'>Seamless Integration and User-Friendly Interface:
                            </h3></div>
                        <p className='content_para pl-6'>Hirestar.io's commitment to user experience is evident in the seamless integration of the GR+ feature into its platform. With an intuitive interface designed for ease of use, HR professionals can navigate the system effortlessly, minimizing training time and maximizing productivity.
                        </p>
                    </div>
                    <div className='content_card animated_content_card'>
                        <div className='flex justify-start items-center'><p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} ><FaCheck /></p>
                            <h3 className='content_heading'>External Links for Further Insight:
                            </h3></div>
                        <p className='content_para pl-6'>For additional insights into talent acquisition best practices and industry trends, consider exploring the following resources:
                        </p>
                    </div>
                </div>
            </div>
            <div className="px-8  py-12">
                <Faq />
            </div>
        </div>
    )
}
