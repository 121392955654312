import React, { useEffect, useState } from 'react'
import { Loading } from '../Blog/IndividualBlogPage/Loading';

export const IndividualCaseStudyPage = ({ clients_case_study_array }) => {
    const [currentBlog, setCurrentBlog] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { pathname } = window.location;
    const hash = window.location.hash;
    function matchAlphanumeric(str1, str2) {
      // Remove non-alphanumeric characters from both strings
      const cleanStr1 = str1.replace(/[^a-zA-Z0-9]/g, "");
      const cleanStr2 = str2.replace(/[^a-zA-Z0-9]/g, "");
  
      // Compare the cleaned strings
      return cleanStr1 === cleanStr2;
    }
    console.log(hash); // This will give you the fragment identifier, e.g., #03:+Cryptocurrency+Explained
    useEffect(() => {
      window.scrollTo(0, 0);
  }, []);
    console.log(pathname);
    console.log(pathname + hash);
    // let blogTitle;
    // if (hash) {
    //   blogTitle = decodeURIComponent(pathname + hash).substring(13).replace(/\+/g, " ");
    // }
    // else{
      
    // }
    let lastIndex = decodeURIComponent(pathname).lastIndexOf('/');
    const blogTitle = decodeURIComponent(pathname).substring(lastIndex + 1).replace(/\+/g, " ");
    // const blogTitle = decodeURIComponent(pathname).substring(23).replace(/\+/g, " ");
    
    useEffect(() => {
      const findBlog = async () => {
        const matchingBlog = clients_case_study_array.find((blog) =>
          matchAlphanumeric(blog.case_study_name, blogTitle)
        );
        if (matchingBlog) {
          setCurrentBlog(matchingBlog);
        } else {
          // Handle error gracefully, e.g., redirect to 404 page
          console.error(`Blog with slug "${blogTitle}" not found.`);
        }
        setIsLoading(false);
      };
  
      findBlog();
    }, [blogTitle, clients_case_study_array]); // Replace '+' with space
  
    console.log(blogTitle);
  
    //   const selectedBlog = clients_case_study_array.find(blog => blog.title === blogTitle);
  
    if (isLoading) {
      return <Loading />;
    }
  
    if (!currentBlog) {
      return <div>Blog not found.</div>; // Or redirect to 404 page
    }
  
    return (
      <div className="container mx-auto mt-12 FontFamily">
        <header className="w-full ">
          <div className="py-12 px-4 ">
            <div className="flex flex-row gap-x-28 mb-16 justify-between items-start">
              <div className=" flex flex-col justify-start items-start mr-auto ">
                <div className="mb-4">
                  <p className="text-5xl font-bold leading-tight tracking-tight">
                    {currentBlog.case_study_name}
                  </p>
                </div>
                <div className="flex items-baseline flex-wrap font-medium gap-x-[1ch] gap-y-[1ch] text-gray-500">
                  <div className="text-xl font-bold text-gray-500"></div>
                  {/* <div className="flex flex-wrap gap-col-[0.5ch] gap-row-[0.5ch]">
                    <div>7 Jun</div>
                    <div className="ml-[0.5ch]">,</div>
                    <div className="flex items-baseline gap-row-[0.5ch] gap-col-[0.5ch] ml-[0.5ch]">
                      <div>4</div>
                      <div className="ml-[0.5ch]">min read</div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="h-[70vh] mb-12 w-full relative">
            <img
              className="w-full h-full object-cover absolute"
              src={currentBlog.image}
              alt=""
            />
          </div>
        </header>
        <section className="blog_body">
          <div className="p-4">
            <div className="container w-full flex items-center content-center gap-x-12 gap-y-12">
              <div
                className="py-12 px-12 text-lg"
                dangerouslySetInnerHTML={{ __html: currentBlog.description }}
              >
                {/* {currentBlog.description} */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  };
