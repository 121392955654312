import React, { useEffect } from 'react';
import svg1 from "../assets/SSVG-SHAPES/blob (3).svg"
// import offerletter from "../assets/ProductPage_IMG/offer_letter-transformed (1).jpeg";
import offerletter from "../assets/ProductPage_IMG/offer-letter.jpeg"
import { FaCheck } from "react-icons/fa6";
import { Link, useLocation } from 'react-router-dom';
import { Faq } from '../Components/Case_Study/Faq';

export const OffersProduct = () => {


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div>
                <div className='boxy w-11/12 m-auto'>
                    <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1">
                        <div className="part-1 boxy">
                            <div className="ele-center-flex2 mt-28 lg:mt-40 sm:mt-28">
                                <div className=" m-4 text-lg">
                                    <div className='from-neutral-900 text-5xl font-bold '><h1 className='text-wrap Verification'><span className='Self' >Background</span> screening made easy</h1></div>
                                    <p className='mt-10 text-lg'>Gain <b>key insights</b>  into a candidate’s <b>personality, working style</b>  and <b>growth areas</b> and <b>define, understand</b> and <b>manage hiring</b>  risks.</p>
                                    <div className="flex  items-center flex-wrap">
                                        <div className='mt-10'><Link to={"/contact-us"} > <button className='rounded-lg text-white font-semibold border-sky-600 h-12 w-[120%] Speak-btn'>Speak to an expert</button></Link> </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="part-2">
                            <div className='ele-center-flexs justify-center '>
                                <div className='p-8 '>
                                    <img src={offerletter} alt="" className='w-full shadow-2xl' />
                                    {/* <img src={svg1} alt="" className='w-[100%] ' /> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-3/4 mt-10 text-center m-auto'>
                <p className="content_main_heading">
                    Fit and proper tests - maintain the integrity and stability of your financially regulated company.
                </p>
            </div>
            <div className='container  mt-10 m-auto'>
                <div className='w-11/12 m-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 grid-flow-row gap-8'>
                    <div className='content_card animated_content_card'>
                        <div className='flex justify-start items-center'><p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} ><FaCheck /></p><h3 className='content_heading'>Virtual Offer Experience:</h3></div>
                        <p className='content_para pl-6'>Step into the future with a virtual offer experience. This innovative portal replaces
                            conventional methods, providing a dynamic and interactive platform for releasing
                            offer letters. Candidates are greeted with a modernized, engaging process that sets
                            the stage for a positive onboarding journey.
                        </p>
                    </div>
                    <div className='content_card animated_content_card'>
                        <div className='flex justify-start items-center'><p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} ><FaCheck /></p><h3 className='content_heading'>Effortless Offer Tracking:</h3></div>
                        <p className='content_para pl-6'>No more lost inboxes or misplaced emails. Track every offer seamlessly within the
                            portal, ensuring that no detail goes unnoticed. The Offer Experience Portal keeps you
                            in control, providing a centralized hub for all your offer-related activities.
                        </p>
                    </div>
                    <div className='content_card animated_content_card'>
                        <div className='flex justify-start items-center'><p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} ><FaCheck /></p><h3 className='content_heading'>Issue Resolution:</h3></div>
                        <p className='content_para pl-6'>Tackle hiring challenges head-on. The portal addresses common issues like
                            moonlighting, offer shopping, and late joining by streamlining the offer process. Stay
                            ahead of potential pitfalls and ensure a smooth transition from offer to onboarding.
                        </p>
                    </div>
                    <div className='content_card animated_content_card'>
                        <div className='flex justify-start items-center'><p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} ><FaCheck /></p><h3 className='content_heading'>Customized Employee Portal:
                        </h3></div>
                        <p className='content_para pl-6'>Extend the portal's benefits to your employees. The Offer Experience includes a
                            dedicated employee portal, facilitating a user-friendly and straightforward
                            onboarding process. Your team can easily navigate, ensuring a positive start to their
                            journey with your organization.
                        </p>
                    </div>
                    <div className='content_card animated_content_card'>
                        <div className='flex justify-start items-center'><p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} ><FaCheck /></p><h3 className='content_heading'>Integrated Letter of Intent:
                        </h3></div>
                        <p className='content_para pl-6'>Forge meaningful connections from the start. The portal introduces a Letter of Intent
                            feature, enabling you to create first interaction letters for employees before they join.
                            Seamlessly integrated with our background verification, this feature kickstarts the
                            comprehensive onboarding process.
                        </p>
                    </div>
                    <div className='content_card animated_content_card'>
                        <div className='flex justify-start items-center'><p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} ><FaCheck /></p><h3 className='content_heading'>Huge Love from HR:
                        </h3></div>
                        <p className='content_para pl-6'>Experience why HR professionals adore Offer Experience. Its extensive range of
                            features and customization options provide a tailor-made solution for every hiring
                            need. Join the ranks of satisfied HR teams and elevate your hiring game.</p>
                    </div>
                    <div className='content_card animated_content_card'>
                        <div className='flex justify-start'>
                            <p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} className='justify-items-start'><FaCheck /></p>
                            <p className='content_para pl-1'>Step into a new era of hiring efficiency with Hirestar.io's Offer Experience Portal.
                                Embrace the future, streamline your processes, and ensure a positive experience for
                                both candidates and employees. Elevate your hiring journey - act now!</p>
                        </div>
                    </div>
                </div>
                <div className="px-8  py-12">
                    <Faq />
                </div>
            </div>
        </>
    )
}
