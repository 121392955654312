import React from "react";
import { Link } from "react-router-dom";
import blogs from "../../assets/resourcesDropDown/blog.png";
import webinars from "../../assets/resourcesDropDown/webinars.png";
import customer_stories from "../../assets/resourcesDropDown/customer_stories.png";
import help_center from "../../assets/resourcesDropDown/help_center.png";
import case_studies from "../../assets/resourcesDropDown/case_studies.png";
import reports from "../../assets/resourcesDropDown/reports.png";

export const Explore_categories = () => {
  const explore_categories_array = [
    {
      name: "Blogs",
      image: blogs,
      path:"/blogs"
    },
    {
      name: "Videos",
      image: webinars,
      path:"/videos"
    },
    {
      name: "Case Studies",
      image: case_studies,
      path:"/case-studies"
    },
    {
      name: "Help Center",
      image: help_center,
      path:"/help-center"
    }
  ];
  return (
    <div className="mt-8 pt-12 pb-12 FontFamily">
      <div className="text-center py-12 ">
        <p className="text-xl font-base font-bold tracking-wide uppercase text-gray-400">
          Explore Categories
        </p>
      </div>
      <div className="flex gap-8 justify-items-center p-4 flex-wrap px-16">
        {explore_categories_array.map((ele) => (
          <div className="border-2 border-gray-200 hover:border-gray-300 min-h-[10vw] flex-auto">
            <Link to={ele.path} className="flex justify-center flex-col gap-y-4 items-center relative font-bold py-5 px-16 ">
              <div className="w-8">
                <img src={ele.image} alt="" className="w-8 object-cover" />
              </div>
              <p>{ele.name}</p>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};
