import React, { useEffect } from "react";
import { Faq } from "../Case_Study/Faq";
import { Explore_categories } from "../Case_Study/Explore_categories";
import { useLocation } from "react-router";


export const ResourcesPage = () => {


  const location = useLocation();

  useEffect(() => {
    const sectionId = location.hash.substring(1);
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="Resources" className="mx-auto mt-8 FontFamily">
      <div className="mx-auto">
        <div className="grid-cols-1 text-start p-4 px-8 bg-gradient-to-l from-sky-100">
          <div className="w-full mx-auto">
            <div className=" py-28 text-center">
              <div>
                <p className="text-6xl font-bold text-cyan-600">Explore, learn, discover</p>
              </div>
              <div className="mt-4">
                <p className="text-xl font-semibold">
                  Insights, ideas and inspiration for leaders, hirers and HR
                  professionals.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 px-4">
          <Explore_categories />
        </div>
        <div className="px-8  py-12">
          <Faq />
        </div>
      </div>
    </div>
  );
};
