import React, { useEffect } from "react";
import { Clients_case_study_card } from "./Clients_case_study_card";
import { Explore_categories } from "./Explore_categories";
import { Help } from "./Help";
import { Faq } from "./Faq";

export const CaseStudy = ({clients_case_study_array}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <div className=" mx-auto FontFamily">
      <div className="grid-cols-1 text-center p-4 bg-gradient-to-l from-sky-100">
        <div className=" w-full max-w-3xl mx-auto">
          <div className="py-28 text-center">
            <div>
              <p className="text-6xl font-bold text-cyan-600">Case studies</p>
            </div>
            <div className="mt-4">
              <p className="text-xl font-semibold">
                Find out how the <strong>world's best workplaces</strong> used{" "}
                <strong>Hirestar.io</strong> software.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className=" mt-8 p-4 w-3/4 mx-auto">
        <Clients_case_study_card clients_case_study_array={clients_case_study_array}/>
      </div>
      <div className=" mt-8 px-4 pt-20 pb-20 mx-auto">
        <Explore_categories />
      </div>
      <div className="mt-8 p-4 w-3/4 mx-auto">
        <Faq />
      </div>
    </div>
  );
};
