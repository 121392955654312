import logo from "./logo.svg";
import "./App.css";
import Section_1 from "./Components/Section_1";

import { Testimonials } from "./Components/Testimonials";
import { Services } from "./Components/Services";
import { Clients } from "./Components/Clients";
import Section_3 from "./Components/Section_3";

import Card_1 from "./Cards/Card_1";
import Card_2 from "./Cards/Card_2";
import Card_3 from "./Cards/Card_3";
import Advantages from "./Components/Advantages";
import Footer from "./Components/Footer";
import { BlogPage } from "./Components/Blog/BlogPage";
import Header from "./Components/Header/Header";
import { NavbarProductDropdown } from "./Components/Navbar_Product_Dropdown/Navbar_Product_Dropdown";
import { ServicesDropdown } from "./Components/Services_dropdown/Services_dropdown";
import { AllRoutes } from "./Components/AllRoutes/AllRoutes";
import { OffersProduct } from "./Products/OffersProduct";
// import { GR_portal } from "./Products/gr-plus-portal"

function App() {
  console.log("version 3.0")
  return (
    <div className="FontFamily">
      <Header />
      <AllRoutes />

      {/* <BGV_HRportal/> */}

      {/* <Section_1/> */}
      {/* <NavbarProductDropdown/> */}
      {/* <ServicesDropdown/> */}
      {/* <BlogPage/> */}

      {/* <Section_1 /> */}
      {/*  <div className="boxy">
      <Header />
      <Section_1 />

        <section className="relative mb-12">
        <Card_1 />
        <Card_2 />
        <Card_3 />
      </section>
      {/* <Section_3/> */}
      {/* <BGV_HRportal />
     
     
      {/* <Services /> */}
      {/* <Clients /> */}
      {/* <Testimonials /> */}
      {/* <Section_3 /> */}
      {/* <GR_portal /> */}
      {/* <OffersProduct /> */}
      {/* <Self_Verification /> */}
      {/* <BGV_HRportal /> */}
      <Footer />
    </div>
  );
}

export default App;
