import React from "react";
import { Link } from "react-router-dom";

export const Help = () => {
  return (
    <div className="w-full max-w-3xl mx-auto FontFamily">
      <div className=" py-28 text-center">
        <div className="m-4">
          <img
            className="inline h-12 w-12"
            src="https://assets-global.website-files.com/63f46c1b2a45bbaf7fb7d1e6/6405b3cb08d407328eaab62f_resources.svg"
            alt=""
          />
        </div>
        <div className="m-4">
          <p className="font-bold text-5xl leading-normal">Help Center</p>
        </div>
        <div className="">
          <p className="text-lg">
            Our <strong>team </strong>of <strong>experts</strong> are
            ready and waiting to <strong>help you</strong>. <br />
            <strong>Get in touch today!</strong>
          </p>
        </div>
        <div className="m-6">
          <div className="flex justify-center items-center gap-x-6 gap-y-6 flex-wrap">
            <Link to={"/contact-us"} className="flex justify-center content-center gap-x-2 text-center items-center py-1 text-lg font-semibold max-w-full">
              <p className="transition-all duration-300 hover:mr-1 text-sky-600 hover:text-sky-700 ml-9">Go to help center</p>
              <div className="flex w-4 h-4 flex-col justify-center items-center mt-1 transition-all duration-300 hover:ml-1">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 3L11 8L6 13"
                    stroke="#F1744D"
                    stroke-width="1.5"
                  ></path>
                </svg>
              </div>
            </Link>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};
