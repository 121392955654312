import React, { useEffect } from 'react'
import { useLocation } from 'react-router';
import { Explore_Products } from '../Components/Case_Study/Explore_Products';
import { Faq } from '../Components/Case_Study/Faq';
export const ProductsPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div className="mx-auto mt-8 FontFamily">
            <div className="container mx-auto">
                <div className="container  grid-cols-1 text-start p-4 px-8 bg-gradient-to-l from-sky-100">
                    <div className="container w-full mx-auto">
                        <div className=" py-28 text-center">
                            <div>
                                <p className="text-6xl font-bold text-cyan-600">Inspire, Grow, Succeed</p>
                            </div>
                            <div className="container mt-4">
                                <p className="text-xl font-semibold">
                                    Guidance, strategies, and solutions for managers, recruiters, and HR experts.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mt-8 px-4">
                    <Explore_Products />
                </div>
                <div className="container px-8  py-12">
                    <Faq />
                </div>
            </div>
        </div>
    )
}
