import React, { useEffect } from 'react'
import giff from "../assets/Comp1.gif"
import BlockChain from "../assets/blockchain.png"
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';


export default function
    Card2() {
    const location = useLocation();
    useEffect(() => {
        const sectionId = location.hash.substring(1);
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [location]);

    return (
        <section id="BlockchainSearh" className="section">
            <div className="boxy">
                <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1">
                    <div className="part-1 boxy order-card-last lg:order-first md:order-las sm:order-last">
                        <div className="ele-center-flex justify-end">
                            <div className="box-1">
                                <div className="mx-auto mt-4 mb-3 parent-1"></div>
                                <div className="relative card m-4">
                                    {/* Added alt attribute */}
                                    <img loading="lazy" src={giff} className="img" alt="Blockchain-Search" width="900" height="900" />
                                    <div className="BlockChain_Parent-3 absolute p-2 flex space-x-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="107" viewBox="0 0 10 107" fill="none" loading="lazy">
                                            {/* SVG Paths */}
                                                <path d="M9 7L1 1L5.66667 7L1 13L9 7Z" fill="#FF6853" stroke="#FF6853" stroke-width="2" stroke-linejoin="round" />
                                                <path d="M9 38L1 32L5.66667 38L1 44L9 38Z" fill="#FF6853" stroke="#FF6853" stroke-width="2" stroke-linejoin="round" />
                                                <path d="M9 69L1 63L5.66667 69L1 75L9 69Z" fill="#FF6853" stroke="#FF6853" stroke-width="2" stroke-linejoin="round" />
                                                <path d="M9 100L1 94L5.66667 100L1 106L9 100Z" fill="#FF6853" stroke="#FF6853" stroke-width="2" stroke-linejoin="round" />
                                        </svg>
                                        {/* Added alt attribute */}
                                        <img loading="lazy" src={BlockChain} alt="Blockchain-text" width="200" height="100" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="part-2 boxy lg:order-last md:order-first sm:order-first">
                        <div className="ele-center-flex justify-start">
                            <div className="text-left card-mobile-props lg:ml-12 lg:mr-24">
                                <h2 className='Self'>Blockchain</h2>
                                <h3 className='Verification'>Search</h3>
                                <p className="para1">
                                    Embark on a new era of trust with Hirestar.io's cutting-edge Blockchain technology. Our secure,
                                    <span className="para2"> tamper-proof, and transparent verification</span> redefine reliability. Join us in delivering a seamless, trustworthy experience for both employers and individuals.
                                </p>
                                <div className="mt-4">
                                    <span className="btn">
                                        {/* Wrapped the button in Link component */}
                                        <Link to={""} class="learnMore_btn">Learn More &gt;</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

}
