import React, { useState } from "react";
import ReactPlayer from "react-player/lazy";

export const VideoCard = ({ title, videoUrl }) => {
  // const [isPlaying, setIsPlaying] = useState(false);
  // console.log("video url",videoUrl)

  // const handleVideoClick = () => {
  //   setIsPlaying(!isPlaying);
  // };

  return (
    <div className="max-w-xs bg-white rounded-lg overflow-hidden shadow-lg m-4 h-[280px]">
      <div className="relative max-w-screen-lg mx-auto h-[160px]">
      <ReactPlayer
        url={videoUrl}
        width="100%"
        height="100%"
        controls
      />
      </div>
      <div className="p-4 mt-4">
        <h2 className="text-lg font-semibold mb-2">{title}</h2>
      </div>
    </div>
  );
};
