import React from 'react'
import offer from "../assets/offer.png"
import offer_Exp from "../assets/OfferExpText.png"
import { Link } from 'react-router-dom'

export default function Cards3() {
  return (
    <section id="OfferExperience" className="section">
      <div className="boxy">
        <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1">
          <div className="part-1 boxy">
            <div className="ele-center-flex justify-end">
              <div className="text-left card-mobile-props lg:ml-48">
                <h2 className='Self'>Offer <span className='Verification'>Experience</span></h2>
                <p className="para1">
                  Elevate the candidate journey with Hirestar.io. From job offer letters to seamless acceptance, our secure and transparent log.
                  <span className="para2"> Accelerates decision-making, ensuring a swift and delightful hiring process.</span>
                </p>
                <div className="mt-4">
                  <span className="btn">
                    {/* Wrapped the button in Link component */}
                    <Link to={"/offer-experience"} className="learnMore_btn">Learn More &gt;</Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="part-2 boxy">
            <div className="ele-center-flex justify-start">
              <div className="box-1">
                <div className="mx-auto mt-4 mb-3 parent-1"></div>
                <div className="parent-2">
                  <div className="card">
                    {/* Added alt attribute */}
                    <img loading="lazy" src={offer} className="offer-image p-4" alt="Offer Experience" width="900" height="900" />
                    <div>
                      <div className="Offerparent-3 absolute p-2 flex space-x-6">
                        <div className="ml-2 h-10">
                          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="115" viewBox="0 0 13 115" fill="none" loading="lazy">
                            <path d="M2 5L5 8L11 2" stroke="#00CA79" stroke-width="3" stroke-linecap="round" />
                            <path d="M2 31L5 34L11 28" stroke="#00CA79" stroke-width="3" stroke-linecap="round" />
                            <path d="M2 57L5 60L11 54" stroke="#00CA79" stroke-width="3" stroke-linecap="round" />
                            <path d="M2 83L5 86L11 80" stroke="#00CA79" stroke-width="3" stroke-linecap="round" />
                            <path d="M2 109L5 112L11 106" stroke="#00CA79" stroke-width="3" stroke-linecap="round" />
                          </svg>
                        </div>
                        {/* Added alt attribute */}
                        <img loading="lazy" src={offer_Exp} alt="Offer-text" width="200" height="200" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

}
