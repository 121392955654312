import React from 'react'
import card3 from "../assets/card3.webp"
import { FaCheck } from "react-icons/fa6";


export default function Cards3() {
  return (
    <div>
 {/*      <div className='boxy'>
        <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1">
          <div className="part-1 boxy">
            <div className="ele-center-flex2 mt-40 justify-center">
              <div className=" m-4  w-[70%] font-semibold text-lg">
                <div className='from-neutral-900 text-5xl font-bold '><h1 className='text-wrap '>Verepass Career Credentials</h1></div>
                <div className='mt-16'>Securely held on the blockchain, your fully-verified career credentials reduce check requirements and save time and money.</div>

              </div>

            </div>
          </div>
          <div className="part-2 boxy">
            <div className='ele-center-flexs justify-center '>
              <div className='p-8'>
                <img src={card3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div> */}


      <div className='w-3/4 mt-10 text-center m-auto'>
        <p className="content_main_heading">
          Strengthen Financial Integrity and Stability with Fit and Proper Tests
        </p>
      </div>
      <div className='container  mt-10 m-auto'>
        <div className='w-11/12 m-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 grid-flow-row gap-8 p-10'>
          <div className='content_card animated_content_card'>
            {/* <h3 className='content_heading'>Virtual Offer Experience:</h3> */}
            <div className='flex justify-start'>
              <p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} className='justify-items-start'><FaCheck /></p>
              <p className='content_para pl-1 ml-2'><b>Identity verification</b> is a crucial process in ensuring the security and trustworthiness of individuals engaging in various transactions, both online and offline. It involves confirming that an individual's claimed identity matches the information on file, typically through the examination of government-issued identification documents, biometric data, or other credentials. By implementing robust identity verification procedures, organizations can mitigate the risks associated with fraud, identity theft, and unauthorized access. Additionally, identity verification plays a vital role in regulatory compliance, particularly in industries such as finance, healthcare, and e-commerce, where strict Know Your Customer (KYC) and Anti-Money Laundering (AML) regulations are enforced. Through accurate and efficient identity verification processes, businesses can enhance customer trust, safeguard sensitive information, and maintain compliance with legal requirements.
              </p>
            </div>
          </div>
          <div className='content_card animated_content_card'>
            {/* <h3 className='content_heading'>Effortless Offer Tracking:</h3> */}
            <div className='flex justify-start'>
              <p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} className='justify-items-start'><FaCheck /></p>
              <p className='content_para pl-1 ml-2'><b>Degree verification</b> is a fundamental process that ensures the authenticity and validity of an individual's educational qualifications. It involves verifying the claimed academic degrees, diplomas, or certifications by contacting the respective educational institutions or relevant authorities. This verification process confirms details such as the institution attended, dates of enrollment, graduation, and the degree or credential earned. Employers, academic institutions, and licensing boards commonly use degree verification to make informed decisions regarding employment, further education, or professional licensure. By conducting thorough degree verification, organizations can mitigate the risks associated with credential fraud, ensure the competence and credibility of individuals, and uphold the integrity of their educational and professional standards.


              </p>
            </div>
          </div>
          <div className='content_card animated_content_card'>
            {/* <h3 className='content_heading'>Proactive Issue Resolution:</h3> */}
            <div className='flex justify-start'>
              <p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} className='justify-items-start'><FaCheck /></p>
              <p className='content_para pl-1 ml-2'><b>Employment verification</b> is a critical process used by employers to confirm the accuracy of an individual's work history and employment details. This typically involves contacting previous employers or relevant sources to verify information such as dates of employment, job titles held, duties performed, and reasons for leaving. Employers may also seek to verify salary information and eligibility for rehire.
                Employment verification serves several important purposes. It helps employers make informed hiring decisions, ensuring that candidates possess the necessary experience and qualifications for a role. It also helps to detect any discrepancies or inaccuracies in a candidate's resume or application, reducing the risk of hiring individuals who may misrepresent their employment history. Additionally, employment verification is often required for background checks, regulatory compliance, and to mitigate potential risks associated with fraudulent or dishonest applicants. Overall, employment verification is an essential step in the hiring process, helping employers build trust and confidence in their workforce.


              </p>
            </div>
          </div>
          <div className='content_card animated_content_card'>
            {/* <h3 className='content_heading'>Customized Employee Portal:
                        </h3> */}
            <div className='flex justify-start'>
              <p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} className='justify-items-start'><FaCheck /></p>
              <p className='content_para pl-1 ml-2'>
                <b>Financial integrity checks</b> are comprehensive evaluations conducted to ensure the honesty, transparency, and reliability of financial activities within an organization. These checks involve a thorough examination of financial records, transactions, processes, and controls to detect any irregularities, errors, or instances of fraud. The primary objective of financial integrity checks is to uphold the integrity and credibility of financial information, safeguard assets, and mitigate risks associated with financial misconduct or mismanagement.

              </p>
            </div>
          </div>
          <div className='content_card animated_content_card'>
            {/* <h3 className='content_heading'>Integrated Letter of Intent:
                        </h3> */}
            <div className='flex justify-start'>
              <p style={{ color: "green", fontSize: "22px", fontWeight: "bold" }} className='justify-items-start'><FaCheck /></p>
              <p className='content_para pl-1 ml-2'>
                <b>Crime check verification</b> involves conducting thorough background checks on individuals to identify any criminal history or legal issues. This process typically includes searching various databases, court records, and law enforcement records to verify if an individual has been involved in criminal activities, such as arrests, convictions, or pending charges.

              </p>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
